import { Card } from '@/components/ui/card'
import { cn } from '@/lib/utils'
import { Brain } from 'lucide-react'

interface TimelineLoadingProps {
  isAnalyzing?: boolean
}

export default function TimelineLoading({ isAnalyzing }: TimelineLoadingProps) {
  return (
    <div className="space-y-8">
      {isAnalyzing && (
        <Card className="p-6 border-blue-200/60 bg-blue-50/50">
          <div className="flex items-center gap-3">
            <div className="shrink-0">
              <Brain className="h-5 w-5 text-blue-500 animate-pulse" />
            </div>
            <div className="space-y-1">
              <h3 className="font-medium text-blue-900">
                Analyzing device data...
              </h3>
              <p className="text-sm text-blue-700">
                Our AI is processing your timeline data. This might take up to 30 seconds as we analyze patterns, identify issues, and generate recommendations.
              </p>
            </div>
          </div>
        </Card>
      )}

      {/* Status Overview Loading */}
      <div className="grid gap-6 sm:grid-cols-2 xl:grid-cols-4">
        {[...Array(4)].map((_, i) => (
          <Card 
            key={i} 
            className="border-slate-200/60 shadow-md overflow-hidden"
          >
            <div className="p-6 space-y-4">
              <div className="h-4 w-24 bg-slate-200/60 rounded-md animate-pulse" />
              <div className="space-y-3">
                {[...Array(2)].map((_, j) => (
                  <div key={j} className="flex items-center justify-between">
                    <div className="h-4 w-32 bg-slate-100 rounded-md animate-pulse" />
                    <div className="h-4 w-16 bg-slate-100 rounded-md animate-pulse" />
                  </div>
                ))}
              </div>
            </div>
          </Card>
        ))}
      </div>

      {/* Timeline Items Loading */}
      <div className="space-y-4">
        {[...Array(3)].map((_, i) => (
          <Card 
            key={i}
            className={cn(
              "border-slate-200/60 shadow-md overflow-hidden",
              "animate-pulse"
            )}
          >
            <div className="p-4 border-b border-slate-200/60 bg-white/50">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-4 flex-1">
                  <div className="h-5 w-48 bg-slate-200 rounded-md" />
                  <div className="h-5 w-24 bg-slate-100 rounded-md" />
                  <div className="flex gap-2">
                    {[...Array(2)].map((_, j) => (
                      <div 
                        key={j}
                        className="h-5 w-16 bg-slate-100 rounded-full"
                      />
                    ))}
                  </div>
                </div>
                <div className="h-4 w-32 bg-slate-100 rounded-md" />
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  )
} 