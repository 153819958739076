import { useNavigate } from 'react-router-dom'
import { useSandSessionHistory } from '@/hooks/useSandSessionHistory'
import { Button } from '@/components/ui/button'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '@/components/ui/table'
import { Trash2, ExternalLink } from 'lucide-react'
import { format } from 'date-fns'

export default function PreviousResultsPage() {
    const navigate = useNavigate()
    const { history, removeSession, clearHistory } = useSandSessionHistory()

    const handleViewResults = (sessionId: string) => {
        navigate(`/sand-analysis/analyze/${sessionId}/result`)
    }

    if (history.length === 0) {
        return (
            <div className="container max-w-3xl mx-auto py-16 space-y-8">
                <div className="space-y-4">
                    <h1 className="text-2xl font-semibold">My Previous Results</h1>
                    <p className="text-muted-foreground">
                        View and access your previous sand analysis results
                    </p>
                </div>

                <div className="text-center py-16">
                    <p className="text-muted-foreground">No previous analyses found</p>
                    <Button
                        size="lg"
                        onClick={() => navigate('/sand-analysis/analyze')}
                        className="mt-4"
                    >
                        Start a new analysis
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <div className="container max-w-3xl mx-auto py-16 space-y-8">
            <div className="space-y-4">
                <div className="flex items-center justify-between">
                    <h1 className="text-2xl font-semibold">My Previous Results</h1>
                    <Button
                        variant="ghost"
                        size="sm"
                        onClick={clearHistory}
                        className="text-muted-foreground hover:text-destructive"
                    >
                        <Trash2 className="h-4 w-4 mr-2" />
                        Clear History
                    </Button>
                </div>
                <p className="text-muted-foreground">
                    View and access your previous sand analysis results
                </p>
            </div>

            <div className="border rounded-lg">
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead>Sample Name</TableHead>
                            <TableHead>Date</TableHead>
                            <TableHead className="w-[100px]"></TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {history.map((entry) => (
                            <TableRow key={entry.sessionId}>
                                <TableCell className="font-medium">
                                    {entry.sampleName}
                                </TableCell>
                                <TableCell>
                                    {format(new Date(entry.date), 'MMM d, yyyy')}
                                </TableCell>
                                <TableCell>
                                    <div className="flex items-center gap-2">
                                        <Button
                                            variant="ghost"
                                            size="icon"
                                            onClick={() => handleViewResults(entry.sessionId)}
                                        >
                                            <ExternalLink className="h-4 w-4" />
                                        </Button>
                                        <Button
                                            variant="ghost"
                                            size="icon"
                                            onClick={() => removeSession(entry.sessionId)}
                                            className="text-muted-foreground hover:text-destructive"
                                        >
                                            <Trash2 className="h-4 w-4" />
                                        </Button>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    )
} 