import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '@/components/ui/button'
import { Alert, AlertDescription } from '@/components/ui/alert'
import { Info, Upload } from 'lucide-react'
import { useState, useEffect } from 'react'
import { AIUploadModal } from '@/components/public/sand-analysis/ai-upload/AIUploadModal'
import { SandDataForm, SandMeasurementFormData } from '@/components/public/sand-analysis/SandDataForm'
import { type SandAnalysisData } from '@/data/sand-analysis'
import useSandAnalysis from '@/hooks/useSandAnalysis'
import { useSandSessionHistory } from '@/hooks/useSandSessionHistory'

export default function WizardDataPage() {
    const { sessionId } = useParams()
    const navigate = useNavigate()
    const { session, createAnalysis, isCreatingAnalysis } = useSandAnalysis(sessionId)
    const { addSession, history } = useSandSessionHistory()
    const [aiData, setAiData] = useState<SandMeasurementFormData>()

    // Get query parameters
    const searchParams = new URLSearchParams(window.location.search)
    const shouldOpenUpload = searchParams.get('openUpload') === 'true'
    const shouldShowManual = searchParams.get('showManual') === 'true'
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(shouldOpenUpload)
    const [showManualEntry, setShowManualEntry] = useState(shouldShowManual)

    // Clean up URL after modal is closed or manual entry is shown
    const handleUploadModalChange = (open: boolean) => {
        setIsUploadModalOpen(open)
        if (!open && shouldOpenUpload) {
            // Remove query parameter without navigating
            const newUrl = window.location.pathname
            window.history.replaceState({}, '', newUrl)
        }
    }

    // Clean up URL when component mounts if manual entry was requested
    useEffect(() => {
        if (shouldShowManual) {
            const newUrl = window.location.pathname
            window.history.replaceState({}, '', newUrl)
        }
    }, [])

    const handleUpload = () => {
        setIsUploadModalOpen(true)
    }

    const handleManualEntry = () => {
        setShowManualEntry(true)
    }

    const handleDataUse = async (data: SandAnalysisData, manualEdit: boolean) => {
        const formData = {
            sampleName: data.sample_info.material_name,
            measurements: data.measurements.map(m => ({
                meshNumber: m.meshNumber,
                diameterMm: m.diameterMm.toString(),
                retainedGrams: m.retainedGrams.toString(),
            }))
        }
        setAiData(formData)
        if (manualEdit) {
            setShowManualEntry(true)
        } else {
            await handleFormSubmit(formData)
        }
    }

    const handleFormSubmit = async (data: SandMeasurementFormData) => {
        const result = await createAnalysis({
            sampleName: data.sampleName,
            measurements: data.measurements.map(m => ({
                meshNumber: m.meshNumber,
                diameterMm: Number(m.diameterMm.replace(',', '.')),
                retainedGrams: Number(m.retainedGrams.replace(',', '.')),
            }))
        })

        // Save to history if we have session data and session is not already in history
        if (session && history.filter(h => h.sessionId === sessionId).length === 0) {
            addSession(session, data.sampleName)
        }

        // Navigate to standard page with the new analysis tab selected
        navigate(`/sand-analysis/analyze/${sessionId}/result?tabId=${result.id}`)
    }

    if (showManualEntry) {
        return (
            <div className="container max-w-3xl mx-auto py-16 space-y-8">
                {/* Progress indicator */}
                <div className="space-y-4">
                    <div className="inline-flex items-center justify-center rounded-full bg-sand px-2.5 py-0.5 text-sm text-slate-600">
                        1/2
                    </div>
                    <h1 className="text-2xl font-semibold">Enter analysis data</h1>
                    <p className="text-muted-foreground">
                        Please enter your sand analysis measurements below
                    </p>
                </div>

                <SandDataForm
                    initialData={aiData}
                    onSubmit={handleFormSubmit}
                    isSubmitting={isCreatingAnalysis}
                />
            </div>
        )
    }

    return (
        <div className="container max-w-3xl mx-auto py-16 space-y-8">
            {/* Progress indicator */}
            <div className="space-y-4">
                <div className="inline-flex items-center justify-center rounded-full bg-sand px-2.5 py-0.5 text-sm text-slate-600">
                    1/2
                </div>
                <h1 className="text-2xl font-semibold">Enter analysis data</h1>
                <p className="text-muted-foreground">
                    You can either upload the report(s) you have received from the lab and have them automatically
                    analysed or enter your data manually.
                </p>
                <p className="text-sm text-muted-foreground">
                    Supported file formats are PDF or Image files up to 10MB
                </p>
            </div>

            {/* Upload and Manual Entry Options */}
            <div className="flex gap-4">
                <Button
                    size="lg"
                    className="flex-1 bg-[#0072CE] hover:bg-[#0072CE]/90 rounded-xl h-auto py-3"
                    onClick={handleUpload}
                >
                    Upload file and analyze with AI
                    <Upload className="ml-2 h-5 w-5" />
                </Button>
                <Button
                    variant="outline"
                    size="lg"
                    className="flex-1 rounded-xl h-auto py-3"
                    onClick={handleManualEntry}
                >
                    I want to enter my numbers manually
                </Button>
            </div>

            {/* Important Notice */}
            <Alert className="bg-slate-50 border border-slate-100">
                <Info className="h-4 w-4" />
                <AlertDescription>
                    <div className="space-y-2">
                        <p className="font-medium">Important Notice</p>
                        <p>The analysis provided is for reference only. You must verify all data with your supplier or laboratory before making any decisions.</p>
                        <p className="font-medium mt-4">Key Points:</p>
                        <ul className="list-disc pl-4 space-y-1">
                            <li>We take no responsibility for the accuracy of the curve analysis</li>
                            <li>The recommended sand curve intervals are based on experience and standard gradation</li>
                            <li>These recommendations are not guaranteed to work in all situations</li>
                        </ul>
                        <p className="text-sm mt-4">By uploading a file, you acknowledge and accept these terms.</p>
                    </div>
                </AlertDescription>
            </Alert>

            {/* AI Upload Modal */}
            <AIUploadModal
                open={isUploadModalOpen}
                onOpenChange={handleUploadModalChange}
                onDataUse={handleDataUse}
            />
        </div>
    )
} 