import { useQuery } from '@tanstack/react-query'
import { getDiagnosticSchedules } from '@/data/admin/diagnostic'

export interface DiagnosticSchedule {
  id: number
  intervalMinutes: number
  startDateTime: string
  endDateTime: string
  lastRequestedAt?: string
  deviceId: number
  imei: string
  createdBy: string
  createdAt: string
  deletedAt?: string
}


function useAdminDiagnosticSchedules(
  imei?: string | null,
  take?: number,
  page?: number,
  orgId?: string | null,
) {
  const abortController = new AbortController()

  return useQuery({
    queryKey: ['admin-diagnostic-schedules', take, page, imei, orgId],
    queryFn: () => getDiagnosticSchedules({
      signal: abortController.signal,
      imei,
      take,
      page,
      orgId
    })
  })
}

export default useAdminDiagnosticSchedules 