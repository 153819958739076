import { Link } from 'react-router-dom'
import { Button } from '@/components/ui/button'

// Image imports
import VectorHorse from '/vector-horse.png?url'
import VectorSand from '/vector-sand.png?url'
import SandExampleGraph from '/sand-example-graph.png?url'

const SandAnalysisLandingPage = () => {
    return (
        <div className="md:max-h-[90vh] overflow-hidden">
            <div className="flex flex-col items-center justify-center py-8 md:min-h-[95vh] text-center overflow-hidden">
                {/* Header Content */}
                <div className="space-y-6 max-w-3xl">
                    <h1 className="text-6xl font-bold tracking-tight text-[#002B49] leading-[78px]">
                        CapillaryFlow Sand Agent
                    </h1>
                    <p className="text-xl text-muted-foreground">
                        Analyze your sand sample and benchmark against industry standards
                    </p>

                    {/* Action Buttons */}
                    <div className="flex items-center justify-center gap-4 pt-4">
                        <Link to="/sand-analysis/analyze">
                            <Button size="lg" className="bg-[#0072CE] hover:bg-[#0072CE]/90">
                                Get started
                            </Button>
                        </Link>
                        {/* <Link to="/sand-analysis/resources/guide">
                            <Button size="lg" variant="outline">
                                Guides and resources
                            </Button>
                        </Link> */}
                    </div>
                </div>

                {/* Images Grid */}
                <div className="mt-16 w-screen relative flex justify-between items-center">
                    <div className="hidden md:block w-[81vw] relative">
                        <img src={VectorHorse} alt="Horse in sand" className="w-full h-auto object-contain" />
                    </div>
                    <div className="w-[85vw] md:w-[40vw] absolute left-1/2 -translate-x-1/2 -top-8">
                        <img src={SandExampleGraph} alt="Sand analysis graph" className="w-full h-auto object-contain" />
                    </div>
                    <div className="hidden md:block w-[29vw] relative">
                        <img src={VectorSand} alt="Golf course" className="w-full h-auto object-contain" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SandAnalysisLandingPage 