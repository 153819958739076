import { Trash2, Plus, Sparkles } from 'lucide-react'
import { Control, useFieldArray } from 'react-hook-form'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import {
    FormControl,
    FormField,
    FormItem,
    FormMessage,
} from '@/components/ui/form'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '@/components/ui/table'
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { Textarea } from "@/components/ui/textarea"
import { useState } from 'react'
import { AIUploadModal } from './ai-upload/AIUploadModal'
import { SandAnalysisData } from '@/data/sand-analysis'

interface SandDataTableProps {
    control: Control<any>
    readOnly?: boolean
}

export function SandDataTable({ control, readOnly }: SandDataTableProps) {
    const [jsonInput, setJsonInput] = useState('')
    const [dialogOpen, setDialogOpen] = useState(false)
    const [aiUploadOpen, setAiUploadOpen] = useState(false)
    const [isDeveloperMode] = useState(() => localStorage.getItem('devMode') === 'true')

    const { fields, append, remove, replace } = useFieldArray({
        control,
        name: 'measurements',
    })

    const handleJsonImport = () => {
        try {
            const data = JSON.parse(jsonInput)
            if (Array.isArray(data)) {
                // Validate each item has the required fields
                const validData = data.every(item =>
                    typeof item === 'object' &&
                    'meshNumber' in item &&
                    'diameterMm' in item &&
                    'retainedGrams' in item
                )

                if (validData) {
                    replace(data)
                    setDialogOpen(false)
                    setJsonInput('')
                } else {
                    throw new Error('Invalid data format')
                }
            } else {
                throw new Error('Data must be an array')
            }
        } catch (error) {
            alert('Invalid JSON format or data structure')
        }
    }

    const handleAiData = (data: SandAnalysisData) => {
        replace(data.measurements)
    }

    return (
        <div className="space-y-2">
            <div className="flex justify-between items-center">
                <h3 className="text-sm font-medium">Analysis Data</h3>
                {!readOnly && (
                    <div className="space-x-1">
                        <Button
                            variant="outline"
                            size="sm"
                            className="h-6 text-xs px-2"
                            onClick={() => setAiUploadOpen(true)}
                        >
                            <Sparkles className="h-3 w-3 mr-1" />
                            AI Upload
                        </Button>
                        {isDeveloperMode && (
                            <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
                                <DialogTrigger asChild>
                                    <Button
                                        variant="outline"
                                        size="sm"
                                        className="h-6 text-xs px-2"
                                    >
                                        Import JSON
                                    </Button>
                                </DialogTrigger>
                                <DialogContent>
                                    <DialogHeader>
                                        <DialogTitle>Import JSON Data</DialogTitle>
                                    </DialogHeader>
                                    <div className="space-y-4">
                                        <Textarea
                                            value={jsonInput}
                                            onChange={(e) => setJsonInput(e.target.value)}
                                            placeholder='[{"meshNumber": "#5", "diameterMm": 4.0, "retainedGrams": 0.00}, ...]'
                                            className="h-[200px] font-mono text-sm"
                                        />
                                        <Button onClick={handleJsonImport} className="w-full">
                                            Import
                                        </Button>
                                    </div>
                                </DialogContent>
                            </Dialog>
                        )}
                    </div>
                )}
            </div>

            <div className="rounded-md border">
                <Table>
                    <TableHeader>
                        <TableRow className="h-8">
                            <TableHead className="py-1 text-xs">#</TableHead>
                            <TableHead className="py-1 text-xs">Ø (mm)</TableHead>
                            <TableHead className="py-1 text-xs">Retained. (g)</TableHead>
                            {!readOnly && <TableHead className="w-[32px] p-0"></TableHead>}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {fields.map((field, index) => (
                            <TableRow key={field.id} className="h-8 group">
                                <TableCell className="py-1">
                                    <FormField
                                        control={control}
                                        name={`measurements.${index}.meshNumber`}
                                        render={({ field }) => (
                                            <FormItem className="space-y-0">
                                                <FormControl>
                                                    <Input {...field} placeholder="#" className="h-5 text-xs" disabled={readOnly} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </TableCell>
                                <TableCell className="py-1">
                                    <FormField
                                        control={control}
                                        name={`measurements.${index}.diameterMm`}
                                        render={({ field: { value, onChange, ...field } }) => (
                                            <FormItem className="space-y-0">
                                                <FormControl>
                                                    <Input
                                                        {...field}
                                                        value={value}
                                                        onChange={e => {
                                                            const normalized = e.target.value.replace(',', '.')
                                                            onChange(normalized)
                                                        }}
                                                        className="h-5 text-xs"
                                                        disabled={readOnly}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </TableCell>
                                <TableCell className="py-1">
                                    <FormField
                                        control={control}
                                        name={`measurements.${index}.retainedGrams`}
                                        render={({ field: { value, onChange, ...field } }) => (
                                            <FormItem className="space-y-0">
                                                <FormControl>
                                                    <Input
                                                        {...field}
                                                        value={value}
                                                        onChange={e => {
                                                            const normalized = e.target.value.replace(',', '.')
                                                            onChange(normalized)
                                                        }}
                                                        className="h-5 text-xs"
                                                        disabled={readOnly}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </TableCell>
                                {!readOnly && (
                                    <TableCell className="py-0.5">
                                        <Button
                                            type="button"
                                            variant="ghost"
                                            size="icon"
                                            className="h-5 w-5 opacity-0 group-hover:opacity-100 transition-opacity"
                                            onClick={() => remove(index)}
                                            tabIndex={-1}
                                        >
                                            <Trash2 className="h-3 w-3" />
                                        </Button>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                        {!readOnly && (
                            <TableRow>
                                <TableCell colSpan={4} className="p-0">
                                    <Button
                                        variant="ghost"
                                        className="h-6 w-full rounded-none hover:bg-muted/50 text-xs text-muted-foreground"
                                        onClick={() => append({
                                            meshNumber: '',
                                            diameterMm: 0,
                                            retainedGrams: 0,
                                        })}
                                    >
                                        <Plus className="h-3 w-3 mr-1" />
                                        Add row
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>

            <AIUploadModal
                open={aiUploadOpen}
                onOpenChange={setAiUploadOpen}
                onDataUse={handleAiData}
                isDeveloperMode={isDeveloperMode}
            />
        </div>
    )
} 