import { useState, useEffect } from 'react'
import type { SandAnalysisSession } from '@/data/sand-analysis'

const STORAGE_KEY = 'flowtrack_session_history'
const MAX_HISTORY_SIZE = 50

interface SessionHistoryEntry {
    sessionId: string
    date: string
    sampleName: string
    contactName?: string
    contactEmail?: string
}

// Storage utilities
const saveToStorage = (data: SessionHistoryEntry[]) => {
    try {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(data))
    } catch (error) {
        console.error('Failed to save session history:', error)
    }
}

const loadFromStorage = (): SessionHistoryEntry[] => {
    try {
        const data = localStorage.getItem(STORAGE_KEY)
        return data ? JSON.parse(data) : []
    } catch (error) {
        console.error('Failed to load session history:', error)
        return []
    }
}

export function useSandSessionHistory() {
    // Initialize state with data from localStorage
    const [history, setHistory] = useState<SessionHistoryEntry[]>(() => loadFromStorage())

    // Update both state and storage
    const updateHistory = (newHistory: SessionHistoryEntry[]) => {
        setHistory(newHistory)
        saveToStorage(newHistory)
    }

    const addSession = (session: SandAnalysisSession, sampleName: string) => {
        const newEntry: SessionHistoryEntry = {
            sessionId: session.id,
            date: new Date().toISOString(),
            sampleName,
            contactName: session.contactName,
            contactEmail: session.contactEmail
        }

        const newHistory = [newEntry, ...history].slice(0, MAX_HISTORY_SIZE)
        updateHistory(newHistory)
    }

    const removeSession = (sessionId: string) => {
        const newHistory = history.filter(entry => entry.sessionId !== sessionId)
        updateHistory(newHistory)
    }

    const clearHistory = () => {
        updateHistory([])
    }

    // Sync with other tabs/windows
    useEffect(() => {
        const handleStorageChange = (event: StorageEvent) => {
            if (event.key === STORAGE_KEY) {
                const newData = event.newValue ? JSON.parse(event.newValue) : []
                setHistory(newData)
            }
        }

        window.addEventListener('storage', handleStorageChange)
        return () => window.removeEventListener('storage', handleStorageChange)
    }, [])

    return {
        history,
        addSession,
        removeSession,
        clearHistory
    }
} 