import { useMemo } from 'react'
import type { SandMeasurement } from '@/data/sand-analysis'

export interface CalculatedRow extends SandMeasurement {
    accumulativeRetain: number  // Percentage
    massRetained: number       // Percentage
    passing: number           // Percentage
}

export function useSandCalculations(measurements: SandMeasurement[]): CalculatedRow[] {
    return useMemo(() => {
        // Skip calculations if no measurements
        if (!measurements?.length) return []

        // Calculate total grams first - parse strings to numbers
        const totalGrams = measurements.reduce(
            (sum, row) => sum + (parseFloat(row.retainedGrams as any) || 0),
            0
        )

        // Skip percentage calculations if no total (avoid division by zero)
        if (totalGrams === 0) {
            return measurements.map(row => ({
                ...row,
                accumulativeRetain: 0,
                massRetained: 0,
                passing: 100,
            }))
        }

        let accumulativeGrams = 0

        return measurements.map(row => {
            // Add current row's grams to accumulative - parse string to number
            const retainedGrams = parseFloat(row.retainedGrams as any) || 0
            accumulativeGrams += retainedGrams

            // Calculate percentages
            const accumulativeRetain = (accumulativeGrams / totalGrams) * 100
            const massRetained = (retainedGrams / totalGrams) * 100
            const passing = 100 - accumulativeRetain

            return {
                ...row,
                accumulativeRetain: Number(accumulativeRetain.toFixed(2)),
                massRetained: Number(massRetained.toFixed(2)),
                passing: Number(passing.toFixed(2)),
            }
        })
    }, [measurements])
}