import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Loader2 } from 'lucide-react'
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import type { ReferenceStandard, SandMeasurementResult } from '@/data/sand-analysis'
import { Checkbox } from '@/components/ui/checkbox'

// Sample colors for different lines - keep in sync with AnalysisInterface
const SAMPLE_COLORS = [
    '#3b82f6', // blue
    '#10b981', // green
    '#f59e0b', // yellow
    '#ef4444', // red
    '#8b5cf6', // purple
    '#ec4899', // pink
    '#06b6d4', // cyan
]

// Desaturated colors for reference standards
const STANDARD_COLORS = [
    'rgba(59, 130, 246, 0.5)',  // desaturated blue
    'rgba(16, 185, 129, 0.5)',  // desaturated green
    'rgba(139, 92, 246, 0.5)',  // desaturated purple
    'rgba(236, 72, 153, 0.5)',  // desaturated pink
]

// Different dash patterns for standards - more distinct
const DASH_PATTERNS = [
    '5 5',           // simple dash
    '2 5 10 5',      // dash-dot
    '15 5',          // long dash
    '5 5 2 5 2 5'    // dash-dot-dot
]

interface SandVisualizationProps {
    samples: {
        id: string
        name: string
        measurements: SandMeasurementResult[]
    }[]
    referenceStandards?: ReferenceStandard[]
    hasUserInput: boolean
    isNew: boolean
}

export function SandVisualization({
    samples: initialSamples,
    referenceStandards: initialReferenceStandards = [],
    hasUserInput,
    isNew,
}: SandVisualizationProps) {
    const { t } = useTranslation()
    const [hiddenItems, setHiddenItems] = useState<Record<string, boolean>>({})

    const samples = useMemo(() => {
        return initialSamples.filter(sample => !hiddenItems[sample.id])
    }, [initialSamples, hiddenItems])

    const referenceStandards = useMemo(() => {
        return initialReferenceStandards.filter(standard => !hiddenItems[standard.id])
    }, [initialReferenceStandards, hiddenItems])
    // Transform data for Recharts - combine all data points
    const data = useMemo(() => {
        console.log("re-render", new Date())
        return [
            // Sample measurements
            ...samples.flatMap(sample =>
                sample.measurements
                    .filter(point => point.diameterMm > 0)
                    .map(point => ({
                        diameterMm: point.diameterMm + Math.random() * 0.0001, // Add tiny random to prevent overlap
                        [`sample_${sample.id}`]: point.passingPercentage,
                        sampleName: sample.name, // For tooltip
                    }))
            ),
            // Reference standard boundaries
            ...referenceStandards.flatMap((standard, standardIndex) => [
                // Min boundary points
                ...(standard.minBoundary || [])
                    .filter(point => point.diameterMm > 0)
                    .map(point => ({
                        diameterMm: point.diameterMm + Math.random() * 0.0001,
                        [`min_${standard.id}`]: point.passingPercentage,
                        standardName: standard.name,
                    })),
                // Max boundary points
                ...(standard.maxBoundary || [])
                    .filter(point => point.diameterMm > 0)
                    .map(point => ({
                        diameterMm: point.diameterMm + Math.random() * 0.0001,
                        [`max_${standard.id}`]: point.passingPercentage,
                        standardName: standard.name,
                    }))
            ])
        ].sort((a, b) => a.diameterMm - b.diameterMm)
    }, [samples, referenceStandards])

    const toggleHiddenItem = (id: string, hidden: boolean) => {
        setHiddenItems(prev => ({ ...prev, [id]: hidden }))
    }
    return (
        <div className="space-y-4">
            <div className="mb-4">
                <h3 className="text-sm font-medium mb-1">
                    Result
                </h3>
                <p className="text-xs text-muted-foreground">
                    Logarithmic plot showing the percentage of particles passing through each sieve size
                </p>
            </div>
            <div className="relative">
                <ResponsiveContainer width="100%" height={400}>
                    <LineChart
                        data={data}
                        margin={{ top: 10, right: 30, left: 10, bottom: 40 }}
                    >
                        <CartesianGrid
                            strokeDasharray="3 3"
                            horizontal={true}
                            vertical={true}
                            stroke="hsl(var(--muted-foreground))"
                            opacity={0.2}
                        />
                        <XAxis
                            dataKey="diameterMm"
                            type="number"
                            scale="log"
                            domain={[0.01, 10]}
                            ticks={[0.01, 0.1, 1, 10]}
                            tickFormatter={(val) => val.toString().replace('.', ',')}
                            label={{
                                value: 'Particle Size (mm)',
                                position: 'insideBottom',
                                offset: -20,
                                style: { fontSize: '12px' }
                            }}
                            tick={{ fontSize: 10 }}
                        />
                        <YAxis
                            domain={[0, 100]}
                            ticks={[0, 20, 40, 60, 80, 100]}
                            tickFormatter={(val) => `${val}%`}
                            label={{
                                value: 'Passing (%)',
                                angle: -90,
                                position: 'insideLeft',
                                offset: 10,
                                style: { fontSize: '12px' }
                            }}
                            tick={{ fontSize: 10 }}
                        />
                        <Tooltip
                            formatter={(value: number, name: string) => {
                                if (name.startsWith('sample_')) {
                                    const sampleId = name.replace('sample_', '')
                                    const sample = samples.find(s => s.id === sampleId)
                                    return [`${value.toFixed(2)}%`, sample?.name || 'Sample']
                                }
                                if (name.startsWith('min_') || name.startsWith('max_')) {
                                    const standardId = name.slice(4) // Remove 'min_' or 'max_'
                                    const standard = referenceStandards.find(s => s.id === standardId)
                                    const boundaryType = name.startsWith('min_') ? 'Min' : 'Max'
                                    return [`${value.toFixed(2)}%`, `${standard?.name} ${boundaryType}`]
                                }
                                return [`${value.toFixed(2)}%`, name]
                            }}
                            labelFormatter={(label) => `${Number(label).toFixed(3)} mm`}
                            contentStyle={{
                                backgroundColor: 'hsl(var(--background))',
                                border: '1px solid hsl(var(--border))',
                                borderRadius: '6px',
                                fontSize: '12px',
                            }}
                        />
                        <Legend
                            wrapperStyle={{
                                fontSize: '12px',
                            }}
                            verticalAlign="top"
                            height={36}
                        />

                        {/* Sample Lines */}
                        {samples.map((sample, index) => (
                            <Line
                                key={sample.id}
                                type="linear"
                                dataKey={`sample_${sample.id}`}
                                stroke={SAMPLE_COLORS[index % SAMPLE_COLORS.length]}
                                strokeWidth={3}
                                dot={false}
                                name={sample.name}
                                connectNulls
                            />
                        ))}

                        {/* Reference Standard Boundaries */}
                        {referenceStandards.map((standard, standardIndex) => (
                            <React.Fragment key={standard.id}>
                                <Line
                                    type="linear"
                                    dataKey={`min_${standard.id}`}
                                    stroke={STANDARD_COLORS[standardIndex % STANDARD_COLORS.length]}
                                    strokeWidth={1.5}
                                    strokeDasharray={DASH_PATTERNS[standardIndex % DASH_PATTERNS.length]}
                                    dot={false}
                                    connectNulls
                                    name={`${standard.name} Reference`}
                                />
                                <Line
                                    type="linear"
                                    dataKey={`max_${standard.id}`}
                                    stroke={STANDARD_COLORS[standardIndex % STANDARD_COLORS.length]}
                                    strokeWidth={1.5}
                                    strokeDasharray={DASH_PATTERNS[standardIndex % DASH_PATTERNS.length]}
                                    dot={false}
                                    connectNulls
                                    name={`${standard.name} Reference`}
                                    legendType='none'
                                />
                            </React.Fragment>
                        ))}
                    </LineChart>
                </ResponsiveContainer>

                {/* Checkbox for all samples and references to hide or show */}
                <div className="flex flex-wrap max-w-[400px] gap-2 mx-auto mt-8">

                    {initialSamples.map(sample => (
                        <div className="flex items-center space-x-2" key={sample.id}>
                            <Checkbox
                                className="data-[state=checked]:bg-skyBlue data-[state=checked]:border-skyBlue"
                                key={sample.id}
                                checked={!hiddenItems[sample.id]}
                                onCheckedChange={() => toggleHiddenItem(sample.id, !hiddenItems[sample.id])}
                            />
                            <label
                                htmlFor="includeDeleted"
                                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                            >
                                {sample.name}
                            </label>
                        </div>

                    ))}
                </div>

                {/* Overlay when waiting for analysis */}
                {hasUserInput && (
                    <div className="absolute inset-0 flex flex-col items-center justify-center bg-background/80 backdrop-blur-[1.5px]">
                        <Loader2 className="h-8 w-8 text-muted-foreground animate-spin" />
                        <div className="text-center mt-4">
                            <p className="text-sm font-medium text-muted-foreground">Ready to Analyze</p>
                            <p className="text-xs text-muted-foreground mt-1">Click {isNew ? "Add Sample To Analysis" : "Save Changes"} to see your results</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
} 