import { useTranslation } from 'react-i18next'
import { RefreshCw } from 'lucide-react'
import { useQueryClient } from '@tanstack/react-query'
import {
  NumberParam,
  StringParam,
  BooleanParam,
  useQueryParam,
  withDefault,
} from 'use-query-params'

import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import DeviceImeiAutocomplete from '@/components/v2/selects/DeviceImeiAutocomplete'
import OrganizationNameAutocomplete from '@/components/admin/OrganizationNameAutocomplete'
import { DiagnosticScheduleTable } from '@/components/admin/DiagnosticScheduleTable'
import CreateScheduleModal from '@/components/admin/CreateScheduleModal'

export default function DiagnosticSchedulesPage() {
  const queryClient = useQueryClient()
  const { t } = useTranslation(['admin'])
  const [searchText, setSearchText] = useQueryParam('imei', StringParam)
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 0))
  const [pageSize, setPageSize] = useQueryParam(
    'pageSize',
    withDefault(NumberParam, 20),
  )
  const [orgId, setOrgId] = useQueryParam('orgId', StringParam)
  const [id, setId] = useQueryParam('id', StringParam)

  const handleRefresh = async () => {
    await queryClient.invalidateQueries({ queryKey: ['admin-diagnostic-schedules'] })
  }

  return (
    <div className="px-4">
      <div className="relative my-8">
        <h2 className="text-3xl">{t('Diagnostic Schedules')}</h2>
        <div className="absolute right-0 top-0">
          <Button
            variant="outline"
            onClick={handleRefresh}
          >
            <RefreshCw className="h-4 w-4" />
            <span className="hidden pl-2 text-base md:block">Refresh</span>
          </Button>
        </div>
      </div>

      <div className="my-4 flex flex-wrap gap-4 md:flex-nowrap">
        <div className="min-w-full sm:min-w-[200px]">
          <OrganizationNameAutocomplete
            onSelect={(val) => setOrgId(val)}
            value={orgId}
          />
        </div>
        <div className="min-w-full sm:min-w-[200px]">
          <DeviceImeiAutocomplete
            orgId={orgId}
            imei={searchText || ''}
            onSelect={(val) => setSearchText(val)}
          />
        </div>
        <div className="flex flex-1 justify-end">
          <Button
            type="button"
            variant="outline"
            size="icon"
            onClick={() => setId('new-schedule')}
            title={t('Create Schedule')}
          >
            +
          </Button>
        </div>
      </div>

      <DiagnosticScheduleTable 
        searchText={searchText}
        pageSize={pageSize}
        page={page}
        orgId={orgId}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
      />

      <CreateScheduleModal />
    </div>
  )
} 