import { axiosClient } from '@/lib/axios'
import { AxiosError } from 'axios'

export interface DeviceTimelineResponse {
  timeline: TimelineItem[]
  statusOverview: StatusOverview
  from: string
  to: string
  analysis?: TimelineAnalysis
}

export interface TimelinePeriod {
  startTime: string
  endTime: string
  summary: CycleSummary
  actions: TimelineAction[]
  transition: TransitionEvent | null
  rawDataPoints: any[]
  badges: TimelineBadge[]
}

export interface CycleSummary {
  activeSection: 'Left' | 'Right'
  duration: string
  normalPercentage: number
  lowWaterPercentage: number
  pendingPercentage: number
  leftSection: SectionStats
  rightSection: SectionStats
  signalQuality: SignalQuality
}

export interface SectionStats {
  // Water Levels
  startWaterLevel: number
  endWaterLevel: number
  averageWaterLevel: number
  trendPercentage: number

  // Environmental
  startTemperature: number
  endTemperature: number
  averageTemperature: number

  startEc: number
  endEc: number
  averageEc: number

  startMoisture: number
  endMoisture: number
  averageMoisture: number

  // Valve Operations
  totalFillValveTime: string
  fillValveOperations: number
  totalDrainValveTime: string
  drainValveOperations: number
}

export interface SignalQuality {
  averageSignalStrength: number
  minSignalStrength: number
  maxSignalStrength: number
}

export interface TimelineAction {
  timestamp: string
  type: string
  title: string
  status: string
  parameters?: Record<string, string>
  referenceId?: number
}

export interface TransitionEvent {
  type: string
  description: string
  triggeringAction: TimelineAction | null
  changes: Record<string, string>
}

export interface StatusOverview {
  // System Health
  offlineEvents: number
  totalOfflineTime: number
  configurationChanges: number
  rebootEvents: number
  
  // Operations
  completeCycles: number
  interruptedCycles: number
  sectionChanges: number
  
  // Valve Operations
  totalFillValveOperations: number
  totalDrainValveOperations: number
  totalFillValveTime: string
  totalDrainValveTime: string
  
  // Signal Quality
  averageSignalStrength: number
  signalLossEvents: number

  // Error Summary
  errorCounts: Record<string, number>
  totalErrors: number
  mostCommonError: string
}

export interface TimelineItem {
  startTime: string
  endTime: string
  type: 'Period' | 'Transition'
  data: TimelinePeriod | TransitionEvent
}

export interface RawTimelineItem {
  timestamp: string
  type: 'DataPoint' | 'Diagnostic' | 'Metadata' | 'MetadataDownlink' | 'DataDownlink'
  data: any // Type this better based on your needs
}

export interface TimelineBadge {
  type: string
  label: string
  count: number
  metadata?: Record<string, string>
}

export interface TimelineAnalysis {
  issues: Array<{
    type: string
    description: string
    severity: 0 | 1 | 2  // 0 = Critical, 1 = Warning, 2 = Info
    confidence: number
    detectedAt: string
  }>
  patterns: Array<{
    patternType: string
    description: string
    significance: string
    metrics: Record<string, number>
  }>
  recommendations: Array<{
    action: string
    reasoning: string
    priority: 0 | 1 | 2  // 0 = High, 1 = Medium, 2 = Low
    confidence: number
  }>
  analyzedAt: string
}

export interface ProblemDetails {
  type?: string
  title?: string
  status?: number
  detail?: string
  instance?: string
}

export interface TimelineError extends Error {
  response?: {
    data: ProblemDetails
  }
}

export async function getDeviceTimeline(
  imei: string,
  from?: Date,
  to?: Date,
  analyze?: boolean
): Promise<DeviceTimelineResponse> {
  try {
    const params = new URLSearchParams()
    if (from) params.append('from', from.toISOString())
    if (to) params.append('to', to.toISOString())
    if (analyze) params.append('analyze', 'true')

    const { data } = await axiosClient.get<DeviceTimelineResponse>(
      `/admin/timeline/device/${imei}?${params.toString()}`
    )
    return data
  } catch (error) {
    if (error instanceof AxiosError) {
      const timelineError = new Error(error.response?.data?.detail || error.message) as TimelineError
      timelineError.response = {
        data: error.response?.data
      }
      throw timelineError
    }
    throw error
  }
} 