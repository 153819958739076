import { cn } from '@/lib/utils'

interface ProgressProps {
    value: number
    className?: string
}

export function Progress({ value, className }: ProgressProps) {
    return (
        <div className={cn("relative w-full h-1 bg-muted overflow-hidden rounded-full", className)}>
            <div 
                className="h-full bg-blue-500 transition-all duration-300 ease-in-out"
                style={{ width: `${Math.min(100, Math.max(0, value))}%` }}
            />
        </div>
    )
} 