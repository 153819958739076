import { useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { Button } from '@/components/ui/button'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs'
import { useSandStandards } from '@/hooks/useSandStandards'
import type { SandMeasurement, SandAnalysisResult } from '@/data/sand-analysis'
import { SandDataTable } from './SandDataTable'
import { SandVisualization } from './SandVisualization'
import { Eye, EyeOff, Loader2 } from 'lucide-react'
import { useEffect, useState } from 'react'
import { MultiSandStandardSelector } from './MultiSandStandardSelector'

// Sample colors for different lines - keep in sync with SandVisualization
const SAMPLE_COLORS = [
    '#3b82f6', // blue
    '#10b981', // green
    '#f59e0b', // yellow
    '#ef4444', // red
    '#8b5cf6', // purple
    '#ec4899', // pink
    '#06b6d4', // cyan
]

const sampleSchema = z.object({
    // Session-level fields (shared)
    contactName: z.string().min(1, 'Name is required to run analysis'),
    contactEmail: z.string().email('Please enter a valid email to run analysis'),
    // Analysis details
    sampleName: z.string().min(1, 'Sample name is required').max(24, 'Sample name cannot exceed 24 characters'),
    standardId: z.string().optional(),
    measurements: z.array(z.object({
        meshNumber: z.string(),
        diameterMm: z.union([z.string(), z.number()]).transform(val => Number(val)),
        retainedGrams: z.union([z.string(), z.number()]).transform(val => Number(val)),
    })),
})

export type SampleFormData = z.infer<typeof sampleSchema>

const DEFAULT_SIEVE_SIZES = [
    { mesh: '#', diameter: 0, retainedGrams: 0 },
    { mesh: '#', diameter: 0, retainedGrams: 0 },
    { mesh: '#', diameter: 0, retainedGrams: 0 },
    { mesh: '#', diameter: 0, retainedGrams: 0 },
    { mesh: '#', diameter: 0, retainedGrams: 0 },
    { mesh: '#', diameter: 0, retainedGrams: 0 },
    { mesh: '#', diameter: 0, retainedGrams: 0 },
    { mesh: 'Pan', diameter: 0, retainedGrams: 0 },
]

interface AnalysisInterfaceProps {
    defaultValues?: SampleFormData
    onAnalyze: (data: { sampleName: string; measurements: SandMeasurement[] }) => Promise<void>
    sessionAnalyses: SandAnalysisResult[]
    activeTab: string
    onTabChange: (value: string) => void
}

const MAX_SAMPLES = 3

export function AnalysisInterface({ defaultValues, onAnalyze, sessionAnalyses, activeTab, onTabChange }: AnalysisInterfaceProps) {
    const { data: standards } = useSandStandards()
    const [hiddenSamples, setHiddenSamples] = useState<Set<string>>(new Set())
    const [selectedStandardIds, setSelectedStandardIds] = useState<string[]>([])
    const canAddMoreSamples = sessionAnalyses.length < MAX_SAMPLES

    const form = useForm<SampleFormData>({
        resolver: zodResolver(sampleSchema),
        defaultValues: defaultValues || {
            sampleName: `Sample ${sessionAnalyses.length + 1}`,
            measurements: DEFAULT_SIEVE_SIZES,
        },
    })

    // Auto-select first standard when data loads
    useEffect(() => {
        if (!standards || selectedStandardIds.length === 0) return
        setSelectedStandardIds(standards.map(s => s.id))
    }, [standards])

    // Watch form fields
    const sampleName = useWatch({ control: form.control, name: "sampleName" })
    const showCreateButton = canAddMoreSamples && activeTab !== 'new' && !form.formState.isDirty

    const handleCreateNew = () => {
        onTabChange('new')
        form.reset({
            sampleName: `Sample ${sessionAnalyses.length + 1}`,
            measurements: DEFAULT_SIEVE_SIZES,
        })
    }

    const handleAnalyze = async (data: SampleFormData) => {
        await onAnalyze(data)
        // Reset form with current values to clear dirty state
        form.reset(data)
    }

    const handleTabChange = (value: string) => {
        onTabChange(value)
        // Keep current form values
        const currentValues = form.getValues()
        if (value === 'new') {
            form.reset({
                ...currentValues,
                sampleName: `Your Sample ${sessionAnalyses.length + 1}`,
                measurements: defaultValues?.measurements || []
            })
        } else {
            const analysis = sessionAnalyses.find(a => a.id === value)
            if (analysis) {
                form.reset({
                    ...currentValues,
                    sampleName: analysis.sampleName,
                    measurements: analysis.measurements
                })
            }
        }
    }

    const handleVisibilityToggle = (e: React.MouseEvent, sampleId: string) => {
        e.stopPropagation()  // Don't switch tabs when toggling visibility
        setHiddenSamples(prev => {
            const next = new Set(prev)
            if (next.has(sampleId)) next.delete(sampleId)
            else next.add(sampleId)
            return next
        })
    }

    return (
        <>
            <MultiSandStandardSelector
                value={selectedStandardIds}
                onChange={setSelectedStandardIds}
            />

            <Form {...form}>
                <form onSubmit={form.handleSubmit(handleAnalyze)} className="space-y-6">
                    {/* Main Content Grid */}
                    <div className="grid grid-cols-[1fr,1.5fr] gap-6">
                        {/* Left Side - Sample Management */}
                        <div className="space-y-4">
                            {showCreateButton && (
                                <Button
                                    type="button"
                                    variant="outline"
                                    className="w-full h-8 text-sm bg-primary/5 hover:bg-primary/10"
                                    onClick={handleCreateNew}
                                >
                                    + Create new sample
                                </Button>
                            )}

                            <Tabs value={activeTab} onValueChange={handleTabChange}>
                                <TabsList className="w-full">
                                    {sessionAnalyses.map((analysis, index) => (
                                        <TabsTrigger
                                            key={analysis.id}
                                            value={analysis.id}
                                            className="flex items-center gap-2 pr-2"
                                        >
                                            <div
                                                className="w-2 h-2 rounded-full"
                                                style={{ backgroundColor: SAMPLE_COLORS[index % SAMPLE_COLORS.length] }}
                                            />
                                            <span>{analysis.sampleName}</span>
                                            <button
                                                type="button"
                                                className="ml-2 p-1 hover:bg-slate-100 rounded-full"
                                                onClick={(e) => handleVisibilityToggle(e, analysis.id)}
                                            >
                                                {!hiddenSamples.has(analysis.id) ? (
                                                    <Eye className="h-3 w-3" />
                                                ) : (
                                                    <EyeOff className="h-3 w-3" />
                                                )}
                                            </button>
                                        </TabsTrigger>
                                    ))}
                                    {activeTab === 'new' && (
                                        <TabsTrigger value="new" className="flex items-center gap-2">
                                            <div
                                                className="w-2 h-2 rounded-full"
                                                style={{ backgroundColor: SAMPLE_COLORS[sessionAnalyses.length % SAMPLE_COLORS.length] }}
                                            />
                                            <span>{sampleName}</span>
                                        </TabsTrigger>
                                    )}
                                </TabsList>

                                {/* Sample Data Tables */}
                                {sessionAnalyses.map(analysis => (
                                    <TabsContent key={analysis.id} value={analysis.id} className="space-y-4">
                                        <FormField
                                            control={form.control}
                                            name="sampleName"
                                            render={({ field }) => (
                                                <FormItem className="space-y-1">
                                                    <FormLabel className="text-xs">Sample Name</FormLabel>
                                                    <FormControl>
                                                        <div className="relative">
                                                            <Input {...field} placeholder="Enter sample name" className="h-8 text-sm" />
                                                            <span className="absolute right-2 top-1/2 -translate-y-1/2 text-xs text-muted-foreground">
                                                                {field.value?.length || 0}/24
                                                            </span>
                                                        </div>
                                                    </FormControl>
                                                    <FormMessage className="text-xs" />
                                                </FormItem>
                                            )}
                                        />
                                        <SandDataTable
                                            control={form.control}
                                            readOnly={false}
                                        />
                                        <Button
                                            type="submit"
                                            className="w-full"
                                            disabled={form.formState.isSubmitting || !form.formState.isDirty}
                                        >
                                            {form.formState.isSubmitting ? (
                                                <div className="flex items-center gap-2">
                                                    <Loader2 className="h-3 w-3 animate-spin" />
                                                    <span>{'Saving Changes...'}</span>
                                                </div>
                                            ) : (
                                                'Save Changes'
                                            )}
                                        </Button>
                                    </TabsContent>
                                ))}

                                {/* New Sample Form */}
                                {activeTab === 'new' && (
                                    <TabsContent value="new" className="space-y-4">
                                        <FormField
                                            control={form.control}
                                            name="sampleName"
                                            render={({ field }) => (
                                                <FormItem className="space-y-1">
                                                    <FormLabel className="text-xs">Sample Name</FormLabel>
                                                    <FormControl>
                                                        <div className="relative">
                                                            <Input {...field} placeholder="Enter sample name" className="h-8 text-sm" />
                                                            <span className="absolute right-2 top-1/2 -translate-y-1/2 text-xs text-muted-foreground">
                                                                {field.value?.length || 0}/24
                                                            </span>
                                                        </div>
                                                    </FormControl>
                                                    <FormMessage className="text-xs" />
                                                </FormItem>
                                            )}
                                        />
                                        <SandDataTable
                                            control={form.control}
                                            readOnly={false}
                                        />
                                        <Button
                                            type="submit"
                                            className="w-full"
                                            disabled={form.formState.isSubmitting}
                                        >
                                            {form.formState.isSubmitting ? (
                                                <div className="flex items-center gap-2">
                                                    <Loader2 className="h-3 w-3 animate-spin" />
                                                    <span>Adding Sample...</span>
                                                </div>
                                            ) : (
                                                'Save sample to analysis'
                                            )}
                                        </Button>
                                    </TabsContent>
                                )}
                            </Tabs>
                        </div>

                        {/* Right Side - Visualization */}
                        <div className="space-y-4">
                            {/* Visualization */}
                            <div className="rounded-lg border bg-card p-4">
                                <SandVisualization
                                    samples={sessionAnalyses
                                        .filter(analysis => !hiddenSamples.has(analysis.id))
                                        .map(analysis => ({
                                            id: analysis.id,
                                            name: analysis.sampleName,
                                            measurements: analysis.measurements
                                        }))}
                                    referenceStandards={standards?.filter(s => selectedStandardIds.includes(s.id)) || []}
                                    hasUserInput={form.formState.isDirty}
                                    isNew={activeTab === 'new'}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </Form>
        </>
    )
} 