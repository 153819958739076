import * as Sentry from '@sentry/react'
import { axiosClient } from '@/lib/axios'

export interface ReferenceStandard {
    id: string
    name: string
    minBoundary: { diameterMm: number; passingPercentage: number }[]
    maxBoundary: { diameterMm: number; passingPercentage: number }[]
}

export interface SandMeasurement {
    meshNumber: string
    diameterMm: number
    retainedGrams: number
}

export interface SandMeasurementResult extends SandMeasurement {
    accumulativeRetainedPercentage: number
    massRetainedPercentage: number
    passingPercentage: number
}

// Claude API Response Types
export interface ClaudeContent {
    type: 'text' | 'tool_use'
    text: string | null
    id: string | null
    name: string | null
    input: SandAnalysisData | null
}

export interface SandAnalysisData {
    sample_info: {
        material_name: string
        total_mass_g: number
        test_date: string
        company: string
    }
    measurements: {
        meshNumber: string  // Format: "#18", "#25", etc. or "PAN"
        diameterMm: number
        retainedGrams: number
        isCalculated: boolean
        dataSource: 'direct_measurement' | 'cumulative_difference' | 'percent_conversion' | 'assumed'
    }[]
    warnings: {
        type: 'missing_data' | 'calculation_made' | 'assumption_made' | 'data_inconsistency'
        severity: 'info' | 'warning' | 'error'
        message: string
    }[]
}

export interface SandAnalysisResult {
    id: string
    sampleName: string
    sessionId: string
    measurements: SandMeasurementResult[]
}

export interface SandAnalysisSession {
    id: string
    contactName: string
    contactEmail: string
    standardIds: string[]
    createdAt: string
    updatedAt: string
    analyses: SandAnalysisResult[]
}

export async function analyzeSandReport(base64Images: string[]): Promise<ClaudeContent[]> {
    try {
        const res = await axiosClient.post('/sand-analysis/analyze-image', {
            base64Images
        })
        return res.data
    } catch (err) {
        Sentry.captureException(err)
        throw err
    }
}

export async function createSession(data?: {
    contactName?: string
    contactEmail?: string,
    standardIds?: string[]
}): Promise<SandAnalysisSession> {
    try {
        const res = await axiosClient.post('/sand-analysis/sessions', data || {})
        return res.data
    } catch (err) {
        Sentry.captureException(err)
        throw err
    }
}

export async function getSession(sessionId: string): Promise<SandAnalysisSession> {
    try {
        const res = await axiosClient.get(`/sand-analysis/sessions/${sessionId}`)
        return res.data
    } catch (err) {
        Sentry.captureException(err)
        throw err
    }
}

export async function getStandards(): Promise<ReferenceStandard[]> {
    try {
        const res = await axiosClient.get('/sand-analysis/standards')
        return res.data
    } catch (err) {
        Sentry.captureException(err)
        throw err
    }
}

export async function createSandAnalysis(sessionId: string, data: {
    id?: string
    sampleName: string
    measurements: SandMeasurement[]
}): Promise<SandAnalysisResult> {
    try {
        const formattedData = {
            ...data,
            measurements: data.measurements.map(m => ({
                meshNumber: m.meshNumber,
                diameterMm: Number(m.diameterMm),
                retainedGrams: Number(m.retainedGrams)
            }))
        }

        const res = await axiosClient.post(`/sand-analysis/sessions/${sessionId}/analyses`, formattedData)
        return res.data
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                sessionId,
                id: data.id,
                sampleName: data.sampleName,
                measurementsCount: data.measurements.length,
            }
        })
        throw err
    }
}

export async function updateSessionContact(sessionId: string, data: {
    contactName?: string
    contactEmail?: string
}): Promise<SandAnalysisSession> {
    try {
        const res = await axiosClient.put(`/sand-analysis/sessions/${sessionId}/contact`, data)
        return res.data
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                sessionId,
                ...data
            }
        })
        throw err
    }
} 