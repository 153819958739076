import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Loader2 } from 'lucide-react'
import { useToast } from '@/components/ui/use-toast'
import { AnalysisInterface, SampleFormData } from '@/components/public/sand-analysis/AnalysisInterface'
import { createSession, getSession, createSandAnalysis } from '@/data/sand-analysis'
import type { SandAnalysisResult, SandMeasurement } from '@/data/sand-analysis'

export default function SandAnalysisPage() {
    const { sessionId } = useParams()
    const navigate = useNavigate()
    const { toast } = useToast()
    const [isLoading, setIsLoading] = useState(true)
    const [isAnalyzed, setIsAnalyzed] = useState(false)
    const [analysisResult, setAnalysisResult] = useState<SandAnalysisResult | null>(null)
    const [sessionAnalyses, setSessionAnalyses] = useState<SandAnalysisResult[]>([])
    const [contactDetails, setContactDetails] = useState<{ name: string; email: string }>({ name: '', email: '' })
    const [activeTab, setActiveTab] = useState<string>('new')
    const [defaultValues, setDefaultValues] = useState<SampleFormData | undefined>(undefined)

    // Initialize session
    useEffect(() => {
        const initSession = async () => {
            try {
                if (!sessionId) {
                    // Create new session
                    const session = await createSession()
                    navigate(`/sand-analysis/analyze/${session.id}/data`)
                    return
                }

                // Load existing session
                const session = await getSession(sessionId)
                setSessionAnalyses(session.analyses)
                if (session.contactName || session.contactEmail) {
                    setContactDetails({ name: session.contactName, email: session.contactEmail })
                }
                if (session.analyses.length > 0) {
                    setActiveTab(session.analyses[0].id)
                    setDefaultValues({
                        contactName: session.contactName,
                        contactEmail: session.contactEmail,
                        sampleName: session.analyses[0].sampleName,
                        standardId: session.standardIds[0],
                        measurements: session.analyses[0].measurements,
                    })
                }
            } catch (error) {
                console.error('Failed to initialize session:', error)
                toast({
                    title: 'Session initialization failed',
                    description: 'Please try again later.',
                    variant: 'destructive',
                })
            } finally {
                setIsLoading(false)
            }
        }

        initSession()
    }, [sessionId])

    const fetchSession = async (id: string) => {
        try {
            const session = await getSession(id)
            setSessionAnalyses(session.analyses)
            if (session.contactName || session.contactEmail) {
                setContactDetails({ name: session.contactName, email: session.contactEmail })
            }
        } catch (error) {
            console.error('Failed to fetch session:', error)
        }
    }

    const handleAnalysis = async (data: {
        sampleName: string
        measurements: SandMeasurement[]
    }) => {
        if (!sessionId) return

        try {
            const result = await createSandAnalysis(sessionId, {
                id: activeTab !== 'new' ? activeTab : undefined,
                sampleName: data.sampleName,
                measurements: data.measurements
            })

            // Refetch the session to get updated analyses
            await fetchSession(sessionId)

            setAnalysisResult(result)
            setIsAnalyzed(true)
            setActiveTab(result.id)

            toast({
                title: activeTab === 'new' ? 'Analysis complete' : 'Analysis updated',
                description: activeTab === 'new' ? 'Your sand analysis results are ready.' : 'Your sand analysis has been updated.',
            })
        } catch (error) {
            console.error('Analysis failed:', error)
            toast({
                title: 'Analysis failed',
                description: 'Please try again later.',
                variant: 'destructive',
            })
        }
    }

    if (isLoading) {
        return (
            <div className="min-h-[calc(100vh-3.5rem)] flex items-center justify-center">
                <div className="text-center">
                    <Loader2 className="h-8 w-8 animate-spin mx-auto mb-4" />
                    <p className="text-sm text-muted-foreground">Initializing analysis session...</p>
                </div>
            </div>
        )
    }

    return (
        <div className="container py-8 space-y-8">
            <div>
                <h1 className="text-4xl font-bold tracking-tight">CapillaryFlow Sand Agent</h1>
                <p className="text-xl text-muted-foreground mt-2">
                    Analyze your sand sample and benchmark against industry standards
                </p>
            </div>

            <AnalysisInterface
                defaultValues={defaultValues}
                onAnalyze={handleAnalysis}
                sessionAnalyses={sessionAnalyses}
                activeTab={activeTab}
                onTabChange={setActiveTab}
            />
        </div>
    )
} 