import type { TimelineAnalysis } from '@/data/admin/device-timeline'
import { Card } from '@/components/ui/card'
import { AlertTriangle, Brain, ChevronDown, Lightbulb, TrendingUp } from 'lucide-react'
import { cn } from '@/lib/utils'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface TimelineAnalysisProps {
  analysis: TimelineAnalysis
}

export default function TimelineAnalysisView({ analysis }: TimelineAnalysisProps) {
  const { t } = useTranslation(['admin'])
  const [expanded, setExpanded] = useState(true)

  const toggleSection = () => {
    setExpanded(!expanded)
  }

  const getSeverityColor = (severity: number) => {
    switch (severity) {
      case 0: return 'text-rose-600 bg-rose-50/80'    // Critical
      case 1: return 'text-amber-600 bg-amber-50/80'  // Warning
      case 2: return 'text-blue-600 bg-blue-50/80'    // Info
      default: return 'text-slate-600 bg-slate-50/80'
    }
  }

  const getSeverityLabel = (severity: number) => {
    switch (severity) {
      case 0: return 'Critical'
      case 1: return 'Warning'
      case 2: return 'Info'
      default: return 'Unknown'
    }
  }

  const getPriorityColor = (priority: number) => {
    switch (priority) {
      case 0: return 'text-rose-600 bg-rose-50/80'    // High
      case 1: return 'text-amber-600 bg-amber-50/80'  // Medium
      case 2: return 'text-emerald-600 bg-emerald-50/80'  // Low
      default: return 'text-slate-600 bg-slate-50/80'
    }
  }

  const getPriorityLabel = (priority: number) => {
    switch (priority) {
      case 0: return 'High'
      case 1: return 'Medium'
      case 2: return 'Low'
      default: return 'Unknown'
    }
  }

  const ConfidenceBadge = ({ confidence }: { confidence: number }) => (
    <div className="flex items-center gap-1.5">
      <div className="h-1 w-12 bg-slate-200 rounded-full overflow-hidden">
        <div
          className="h-full bg-blue-500 transition-all duration-300"
          style={{ width: `${confidence * 100}%` }}
        />
      </div>
      <span className="text-xs text-slate-500">
        {Math.round(confidence * 100)}%
      </span>
    </div>
  )

  return (
    <>
      {/* Issues Card */}
      <Card className="overflow-hidden border-slate-200/60 shadow-md h-full">
        <button
          onClick={() => toggleSection()}
          className="w-full px-6 py-4 flex items-center justify-between bg-white/50 border-b border-slate-200/60"
        >
          <div className="flex items-center gap-3">
            <AlertTriangle className="h-5 w-5 text-amber-500" />
            <h3 className="text-base font-medium text-slate-800">
              Issues
            </h3>
            <span className="px-2 py-0.5 rounded-full text-xs font-medium bg-amber-50/80 text-amber-600">
              {analysis.issues.length}
            </span>
          </div>
          <ChevronDown className={cn(
            "h-5 w-5 text-slate-400 transition-transform",
            expanded && "rotate-180"
          )} />
        </button>
        {expanded && (
          <div className="p-6 space-y-4 bg-white/50 max-h-[300px] overflow-y-auto">
            {analysis.issues.map((issue, index) => (
              <div
                key={index}
                className="flex items-start justify-between gap-4 p-4 rounded-lg bg-white/80 border border-slate-200/60"
              >
                <div className="space-y-2">
                  <div className="flex items-center gap-2">
                    <span className={cn(
                      "px-2 py-0.5 rounded-full text-xs font-medium",
                      getSeverityColor(issue.severity)
                    )}>
                      {getSeverityLabel(issue.severity)} - {issue.type}
                    </span>
                  </div>
                  <p className="text-sm text-slate-600">{issue.description}</p>
                </div>
                <ConfidenceBadge confidence={issue.confidence} />
              </div>
            ))}
          </div>
        )}
      </Card>

      {/* Patterns Card */}
      <Card className="overflow-hidden border-slate-200/60 shadow-md h-full">
        <button
          onClick={() => toggleSection()}
          className="w-full px-6 py-4 flex items-center justify-between bg-white/50 border-b border-slate-200/60"
        >
          <div className="flex items-center gap-3">
            <TrendingUp className="h-5 w-5 text-blue-500" />
            <h3 className="text-base font-medium text-slate-800">
              Patterns
            </h3>
          </div>
          <ChevronDown className={cn(
            "h-5 w-5 text-slate-400 transition-transform",
            expanded && "rotate-180"
          )} />
        </button>
        {expanded && (
          <div className="p-6 space-y-4 bg-white/50 max-h-[300px] overflow-y-auto">
            {analysis.patterns.map((pattern, index) => (
              <div
                key={index}
                className="p-4 rounded-lg bg-white/80 border border-slate-200/60 space-y-3"
              >
                <div className="flex items-center justify-between">
                  <span className="text-sm font-medium text-slate-800">
                    {pattern.patternType}
                  </span>
                  <span className="px-2 py-0.5 rounded-full text-xs font-medium bg-blue-50/80 text-blue-600">
                    {pattern.significance}
                  </span>
                </div>
                <p className="text-sm text-slate-600">{pattern.description}</p>
                {Object.entries(pattern.metrics).length > 0 && (
                  <div className="pt-2 border-t border-slate-100">
                    {Object.entries(pattern.metrics).map(([key, value]: [string, number]) => (
                      <div key={key} className="flex justify-between text-sm">
                        <span className="text-slate-500">{key}:</span>
                        <span className="font-medium text-slate-700">{value}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </Card>

      {/* Recommendations Card */}
      <Card className="overflow-hidden border-slate-200/60 shadow-md h-full">
        <button
          onClick={() => toggleSection()}
          className="w-full px-6 py-4 flex items-center justify-between bg-white/50 border-b border-slate-200/60"
        >
          <div className="flex items-center gap-3">
            <Lightbulb className="h-5 w-5 text-emerald-500" />
            <h3 className="text-base font-medium text-slate-800">
              Recommendations
            </h3>
          </div>
          <ChevronDown className={cn(
            "h-5 w-5 text-slate-400 transition-transform",
            expanded && "rotate-180"
          )} />
        </button>
        {expanded && (
          <div className="p-6 space-y-4 bg-white/50 max-h-[300px] overflow-y-auto">
            {analysis.recommendations.map((recommendation, index) => (
              <div
                key={index}
                className="flex items-start justify-between gap-4 p-4 rounded-lg bg-white/80 border border-slate-200/60"
              >
                <div className="space-y-2">
                  <div className="flex items-center gap-2">
                    <span className={cn(
                      "px-2 py-0.5 rounded-full text-xs font-medium",
                      getPriorityColor(recommendation.priority)
                    )}>
                      {getPriorityLabel(recommendation.priority)} Priority
                    </span>
                  </div>
                  <p className="text-sm font-medium text-slate-800">{recommendation.action}</p>
                  <p className="text-sm text-slate-600">{recommendation.reasoning}</p>
                </div>
                <ConfidenceBadge confidence={recommendation.confidence} />
              </div>
            ))}
          </div>
        )}
      </Card>
    </>
  )
} 