import { useTranslation } from 'react-i18next'
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'

interface Point {
    diameterMm: number
    passing: number
}
interface SandAnalysisGraphProps {
    minBoundary: Point[]
    maxBoundary: Point[]
    measurements?: Point[]
}

export function SandAnalysisGraph({ minBoundary, maxBoundary, measurements }: SandAnalysisGraphProps) {
    const { t } = useTranslation(['admin'])
    // Transform data for Recharts - keep series separate
    const data = [
        ...(measurements || []).map(point => ({
            diameterMm: point.diameterMm + Math.random() * 0.0001, // tiny random offset
            samplePassing: point.passing,
            minPassing: null,
            maxPassing: null,
            series: 'sample'
        })),
        ...minBoundary.map(point => ({
            diameterMm: point.diameterMm + Math.random() * 0.0001, // tiny random offset
            minPassing: point.passing,
            maxPassing: null,
            series: 'min'
        })),
        ...maxBoundary.map(point => ({
            diameterMm: point.diameterMm + Math.random() * 0.0001, // tiny random offset
            minPassing: null,
            maxPassing: point.passing,
            series: 'max'
        }))
    ].sort((a, b) => a.diameterMm - b.diameterMm)

    return (
        <ResponsiveContainer width="100%" height={450} className="plot-container py-2">
            <LineChart
                data={data}
                margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
            >
                <CartesianGrid
                    strokeDasharray="3 3"
                    horizontal={true}
                    vertical={true}
                    // Lighter color for grid
                    stroke="#e5e7eb"
                />
                <XAxis
                    dataKey="diameterMm"
                    type="number"
                    scale="log"
                    domain={[0.01, 10]}
                    ticks={[0.01, 0.1, 1, 10]}
                    tickFormatter={(val) => val.toString().replace('.', ',')}
                    label={{
                        value: 'Particle Diameter (mm)',
                        position: 'insideBottom',
                        offset: 0
                    }}
                    className="whitespace-break-spaces text-xs"
                />
                <YAxis
                    domain={[0, 100]}
                    ticks={[0, 25, 50, 75, 100]}
                    tickFormatter={(val) => `${val}%`}
                    label={{
                        value: 'Passing (%)',
                        angle: -90,
                        position: 'left',
                        offset: 10
                    }}
                    className="whitespace-nowrap text-xs"
                />
                <Tooltip
                    formatter={(value: number) => `${value.toFixed(2)}%`}
                    labelFormatter={(label) => `${Number(label).toFixed(3)} mm`}
                    wrapperStyle={{ fontSize: '12px' }}
                />
                <Legend
                    wrapperStyle={{
                        fontSize: '12px',
                        marginTop: '40px'
                    }}
                    verticalAlign="bottom"
                    height={36}
                    align="center"
                />
                <Line
                    type="linear"
                    dataKey="minPassing"
                    stroke="#22c55e"
                    strokeWidth={2}
                    dot={{
                        r: 5,
                        strokeWidth: 2,
                        fill: 'white',
                        stroke: '#22c55e'
                    }}
                    connectNulls
                    name={t('sand_standards.min_boundary_legend')}
                />
                {measurements ? <Line
                    type="linear"
                    dataKey="samplePassing"
                    stroke="cyan"
                    strokeWidth={2}
                    dot={{
                        r: 5,
                        strokeWidth: 2,
                        fill: 'white',
                        stroke: 'cyan'
                    }}
                    connectNulls
                    name={t('sand_standards.sample_legend')}
                /> : null}
                <Line
                    type="linear"
                    dataKey="maxPassing"
                    stroke="#ef4444"
                    strokeWidth={2}
                    dot={{
                        r: 5,
                        strokeWidth: 2,
                        fill: 'white',
                        stroke: '#ef4444'
                    }}
                    connectNulls
                    name={t('sand_standards.max_boundary_legend')}
                />
            </LineChart>
        </ResponsiveContainer>
    )
} 