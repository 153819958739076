import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import { Button } from '@/components/ui/button'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Dialog, DialogContent } from '@/components/ui/dialog'
import useSandAnalysis from '@/hooks/useSandAnalysis'
import { useParams } from 'react-router-dom'

const formSchema = z.object({
    contactName: z.string().min(1, 'Name is required'),
    contactEmail: z.string().email('Please enter a valid email'),
})

type FormData = z.infer<typeof formSchema>

interface SandContactFormModalProps {
    open: boolean
    onOpenChange: (open: boolean) => void
    onSuccess?: () => void
    canClose?: boolean
}

export default function SandContactFormModal({
    open,
    onOpenChange,
    onSuccess,
    canClose = true
}: SandContactFormModalProps) {
    const { sessionId } = useParams()
    const { session, updateContact, isUpdatingContact } = useSandAnalysis(sessionId)

    const form = useForm<FormData>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            contactName: session?.contactName ?? '',
            contactEmail: session?.contactEmail ?? ''
        },
    })

    const onSubmit = async (values: FormData) => {
        await updateContact({
            contactName: values.contactName,
            contactEmail: values.contactEmail,
        })
        onSuccess?.()
        if (canClose) {
            onOpenChange(false)
        }
    }

    return (
        <Dialog
            open={open}
            onOpenChange={(newOpen) => {
                if (canClose) {
                    onOpenChange(newOpen)
                }
            }}
        >
            <DialogContent
                backdropClassName="bg-white/40 dark:bg-slate-950/40 backdrop-blur-[2px]"
                className="max-w-lg p-6 rounded-xl"
                hideCloseButton={!canClose}
            >
                {/* Header */}
                <div className="flex items-center justify-between pb-3 border-b">
                    <h2 className="text-lg font-semibold">Contact Information</h2>
                </div>

                {/* Content */}
                <div className="py-4 space-y-4">
                    <p className="text-sm text-muted-foreground">
                        We just need your contact info to form your analysis
                    </p>

                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                            <FormField
                                control={form.control}
                                name="contactName"
                                render={({ field }) => (
                                    <FormItem className="space-y-1.5">
                                        <FormLabel>Name*</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Your name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="contactEmail"
                                render={({ field }) => (
                                    <FormItem className="space-y-1.5">
                                        <FormLabel>Email*</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Email" type="email" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <div className="flex justify-start pt-2">
                                <Button type="submit" size="default" disabled={isUpdatingContact}>
                                    {isUpdatingContact ? 'Saving...' : 'See my results'}
                                </Button>
                            </div>
                        </form>
                    </Form>
                </div>
            </DialogContent>
        </Dialog>
    )
} 