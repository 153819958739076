import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import {
  getCoreRowModel,
  useReactTable,
  type ColumnDef,
} from '@tanstack/react-table'
import { Button } from '@/components/ui/button'
import { Badge } from '@/components/ui/badge'
import { useQueryClient } from '@tanstack/react-query'
import { useToast } from '@/components/ui/use-toast'
import { Pencil2Icon } from '@radix-ui/react-icons'
import type { DiagnosticSchedule } from '@/data/admin/diagnostic'
import { dateWithTimeInIsoFormat } from '@/utils/dateHelpers'
import PaginatedTable from '@/components/v2/PaginatedTable'
import useAdminDevices from '@/hooks/useAdminDevices'
import { deleteDiagnosticSchedule } from '@/data/admin/diagnostic'
import useAdminDiagnosticSchedules from '@/hooks/useAdminDiagnosticSchedules'
import { StringParam, useQueryParam } from 'use-query-params'
import DeleteCommandButton from '@/components/admin/DeleteCommandButton'

interface DiagnosticScheduleTableProps {
  searchText?: string | null
  pageSize: number
  page: number
  orgId?: string | null
  onPageChange: (page: number) => void
  onPageSizeChange: (pageSize: number) => void
}

export function DiagnosticScheduleTable({
  searchText,
  pageSize,
  page,
  orgId,
  onPageChange,
  onPageSizeChange,
}: DiagnosticScheduleTableProps) {
  const { t } = useTranslation(['admin'])
  const { toast } = useToast()
  const queryClient = useQueryClient()
  const [, setId] = useQueryParam('id', StringParam)
  
  const { data: deviceData } = useAdminDevices()
  const deviceMap = useMemo(() => 
    new Map(deviceData?.devices?.map(d => [d.imei, d.name]) || []),
    [deviceData?.devices]
  )

  const { data, isLoading } = useAdminDiagnosticSchedules(
    searchText,
    pageSize,
    page,
    orgId
  )

  const handleDelete = async (id: number) => {
    try {
      await deleteDiagnosticSchedule(id)
      queryClient.invalidateQueries({ queryKey: ['admin-diagnostic-schedules'] })
      toast({
        title: 'Schedule deleted successfully',
        variant: 'default'
      })
    } catch (err) {
      toast({
        title: 'Failed to delete schedule',
        variant: 'destructive'
      })
    }
  }
  
  const columns = useMemo<ColumnDef<DiagnosticSchedule>[]>(() => [
    {
      id: 'actions',
      cell: ({ row }) => {
        const { id, imei, deletedAt } = row.original

        if (deletedAt) {
          return (
            <Badge variant="destructive">Deleted</Badge>
          )
        }

        return (
          <div className="flex gap-2">
            <Button
              variant="outline"
              type="button"
              className="h-8 w-8 rounded-full border-yellow-700/50 p-0 text-yellow-700 hover:bg-yellow-50 hover:text-yellow-800"
              onClick={() => setId(id.toString())}
            >
              <span className="sr-only">Edit schedule</span>
              <Pencil2Icon className="h-4 w-4" />
            </Button>
            <DeleteCommandButton
              onDelete={async () => handleDelete(id)}
              imei={imei}
            />
          </div>
        )
      },
    },
    {
      accessorKey: 'imei',
      header: t('diagnostics.imei'),
      cell: ({ row }) => {
        const { imei } = row.original
        const deviceName = deviceMap.get(imei)
        return (
          <div className="flex flex-col">
            <span>{imei}</span>
            {deviceName && (
              <span className="text-sm text-slate-500">{deviceName}</span>
            )}
          </div>
        )
      }
    },
    {
      accessorKey: 'intervalMinutes',
      header: t('diagnostics.interval'),
      cell: ({ row }) => `${row.original.intervalMinutes} min`
    },
    {
      accessorKey: 'endDateTime',
      header: t('diagnostics.endDate'),
      cell: ({ row }) => dateWithTimeInIsoFormat(new Date(row.original.endDateTime))
    },
    {
      accessorKey: 'lastRequestedAt',
      header: t('diagnostics.lastRequested'),
      cell: ({ row }) => row.original.lastRequestedAt ? 
        dateWithTimeInIsoFormat(new Date(row.original.lastRequestedAt)) : '-'
    },
    {
      accessorKey: 'status',
      header: t('diagnostics.status'),
      cell: ({ row }) => {
        const now = new Date()
        const end = new Date(row.original.endDateTime)
        const isActive = now <= end && !row.original.deletedAt
        
        return (
          <Badge variant={isActive ? "default" : "secondary"}>
            {isActive ? 'Active' : 'Expired'}
          </Badge>
        )
      }
    },
    {
      accessorKey: 'createdBy',
      header: t('diagnostics.createdBy'),
    },
    {
      accessorKey: 'createdAt',
      header: t('diagnostics.createdAt'),
      cell: ({ row }) => dateWithTimeInIsoFormat(new Date(row.original.createdAt))
    },
    {
      accessorKey: 'deletedAt',
      header: t('diagnostics.deletedAt'),
      cell: ({ row }) => row.original.deletedAt ? 
        dateWithTimeInIsoFormat(new Date(row.original.deletedAt)) : null
    }
  ], [t, deviceMap])

  const table = useReactTable({
    data: data ? data.diagnosticSchedules || [] : [],
    columns,
    pageCount: data?.totalCount ? Math.ceil(data.totalCount / pageSize) : -1,
    state: {
      pagination: {
        pageSize: pageSize,
        pageIndex: page,
      },
    },
    onPaginationChange: (updater) => {
      if (typeof updater !== 'function') return

      const nextState = updater({ pageIndex: page, pageSize: pageSize })

      onPageChange(nextState.pageIndex)
      onPageSizeChange(nextState.pageSize)
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  })

  return <PaginatedTable<DiagnosticSchedule> table={table} isLoading={isLoading} />
} 