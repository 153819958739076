import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import {
    createSandStandard,
    getSandStandard,
    updateSandStandard,
} from '@/data/sand-standards'
import type { CreateUpdateSandStandard } from '@/data/sand-standards'

import { SandStandardForm } from '../../components/admin/sand-standards/SandStandardForm'

export default function EditSandStandardPage() {
    const { t } = useTranslation(['admin'])
    const { toast } = useToast()
    const navigate = useNavigate()
    const { id } = useParams()
    const queryClient = useQueryClient()
    const isNew = id === 'new'

    const { data: standard, isLoading } = useQuery({
        queryKey: ['sand-standard', id],
        queryFn: () => getSandStandard(id!),
        enabled: !isNew,
    })

    const createMutation = useMutation({
        mutationFn: createSandStandard,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['sand-standards'] })
            toast({
                title: t('sand_standards.create_success'),
            })
            navigate('/admin/sand-standards')
        },
        onError: () => {
            toast({
                title: t('sand_standards.create_error'),
                variant: 'destructive',
            })
        },
    })

    const updateMutation = useMutation({
        mutationFn: (data: CreateUpdateSandStandard) => updateSandStandard(id!, data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['sand-standards'] })
            queryClient.invalidateQueries({ queryKey: ['sand-standard', id] })
            toast({
                title: t('sand_standards.update_success'),
            })
            navigate('/admin/sand-standards')
        },
        onError: () => {
            toast({
                title: t('sand_standards.update_error'),
                variant: 'destructive',
            })
        },
    })

    const handleSubmit = async (data: CreateUpdateSandStandard) => {
        if (isNew) {
            await createMutation.mutateAsync(data)
        } else {
            await updateMutation.mutateAsync(data)
        }
    }

    if (!isNew && isLoading) {
        return <div>Loading...</div>
    }

    return (
        <div className="container mx-auto py-6">
            <div className="flex justify-between items-center mb-6">
                <div className="space-y-1">
                    <h1 className="text-2xl font-bold">
                        {isNew
                            ? t('sand_standards.create_title')
                            : t('sand_standards.edit_title')}
                    </h1>
                    <p className="text-muted-foreground">
                        {t('sand_standards.form_description')}
                    </p>
                </div>
                <Button variant="outline" onClick={() => navigate('/admin/sand-standards')}>
                    Cancel
                </Button>
            </div>

            <div className="space-y-6">
                <SandStandardForm
                    standard={standard}
                    onSubmit={handleSubmit}
                    mode={isNew ? 'create' : 'update'}
                />
            </div>
        </div>
    )
} 