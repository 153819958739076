import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'react-i18next'
import { useForm, useWatch } from 'react-hook-form'

import { Button } from '@/components/ui/button'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Switch } from '@/components/ui/switch'
import { Textarea } from '@/components/ui/textarea'
import type { CreateUpdateSandStandard, SandStandard } from '@/data/sand-standards'
import { SandStandardSchema } from '@/data/sand-standards'
import { useSandCalculations } from '@/hooks/useSandCalculations'

import { SandBoundaryTable } from './SandBoundaryTable'
import { SandAnalysisGraph } from './SandAnalysisGraph'

interface SandStandardFormProps {
    standard?: SandStandard
    onSubmit: (data: CreateUpdateSandStandard) => Promise<void>
    mode?: 'create' | 'update'
}

export function SandStandardForm({
    standard,
    onSubmit,
    mode = 'create',
}: SandStandardFormProps) {
    const { t } = useTranslation(['admin', 'common'])

    const form = useForm<CreateUpdateSandStandard>({
        resolver: zodResolver(SandStandardSchema),
        defaultValues: {
            name: standard?.name || '',
            description: standard?.description || '',
            isActive: standard?.isActive ?? true,
            minBoundary: standard?.minBoundary || [{
                meshNumber: '',
                diameterMm: 0,
                retainedGrams: 0,
            }],
            maxBoundary: standard?.maxBoundary || [{
                meshNumber: '',
                diameterMm: 0,
                retainedGrams: 0,
            }],
        },
    })

    // Use useWatch instead of watch for better reactivity
    const minBoundaryValues = useWatch({
        control: form.control,
        name: 'minBoundary',
    })
    const maxBoundaryValues = useWatch({
        control: form.control,
        name: 'maxBoundary',
    })

    // Calculate values for both boundaries
    const minCalculations = useSandCalculations(minBoundaryValues || [])
    const maxCalculations = useSandCalculations(maxBoundaryValues || [])

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-3">
                <div className="grid grid-cols-1 gap-3">
                    {/* Basic Info Section */}
                    <div className="space-y-3">
                        <div className="grid grid-cols-[1fr,auto] gap-3 items-start">
                            <div className="space-y-3">
                                <FormField
                                    control={form.control}
                                    name="name"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel className="text-sm">{t('sand_standards.name')}</FormLabel>
                                            <FormControl>
                                                <Input {...field} className="h-7" />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={form.control}
                                    name="description"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel className="text-sm">{t('sand_standards.description')}</FormLabel>
                                            <FormControl>
                                                <Textarea {...field} className="min-h-[50px] resize-none" />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>

                            <FormField
                                control={form.control}
                                name="isActive"
                                render={({ field }) => (
                                    <FormItem className="mt-6">
                                        <div className="flex items-center gap-2">
                                            <FormLabel className="text-sm m-0 leading-none">
                                                {t('sand_standards.active')}
                                            </FormLabel>
                                            <FormControl>
                                                <Switch
                                                    checked={field.value}
                                                    onCheckedChange={field.onChange}
                                                />
                                            </FormControl>
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </div>
                    </div>

                    {/* Boundaries Section */}
                    <div className="grid lg:grid-cols-2 gap-4 items-start">
                        <div className="space-y-3">
                            <h3 className="text-sm font-medium">{t('sand_standards.boundaries')}</h3>
                            <div className="space-y-3">
                                <SandBoundaryTable
                                    control={form.control}
                                    name="minBoundary"
                                    title={t('sand_standards.min_boundary_title')}
                                    calculatedRows={minCalculations}
                                />
                                <SandBoundaryTable
                                    control={form.control}
                                    name="maxBoundary"
                                    title={t('sand_standards.max_boundary_title')}
                                    calculatedRows={maxCalculations}
                                />
                            </div>
                        </div>
                        
                        <div className="flex items-center justify-center h-full">
                            <SandAnalysisGraph
                                minBoundary={minCalculations}
                                maxBoundary={maxCalculations}
                            />
                        </div>
                    </div>
                </div>

                <Button type="submit" className="w-full h-8">
                    {mode === 'create'
                        ? t('sand_standards.create')
                        : t('sand_standards.update')}
                </Button>
            </form>
        </Form>
    )
} 