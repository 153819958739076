import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { Button } from '@/components/ui/button'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Plus } from 'lucide-react'
import { useState } from 'react'

// Form types
type Measurement = {
    meshNumber: string
    diameterMm: string
    retainedGrams: string
}

const sandDataSchema = z.object({
    sampleName: z.string().min(1, 'Sample name is required'),
    //analysisDate: z.string().min(1, 'Analysis date is required'),
    //totalMass: z.string().min(1, 'Total mass is required'),
    //company: z.string().optional(),
    measurements: z.array(z.object({
        meshNumber: z.string(),
        diameterMm: z.string(),
        retainedGrams: z.string(),
    })).min(1, 'At least one measurement is required'),
})

export type SandMeasurementFormData = z.infer<typeof sandDataSchema>

type SandDataFormProps = {
    initialData?: SandMeasurementFormData
    onSubmit: (data: SandMeasurementFormData) => void
    isSubmitting?: boolean
}

export function SandDataForm({ initialData, onSubmit, isSubmitting = false }: SandDataFormProps) {
    // Convert initial measurements to form format
    const getInitialMeasurements = (): Measurement[] => {
        if (!initialData?.measurements) {
            return [
                { meshNumber: '', diameterMm: '0', retainedGrams: '0' },
                { meshNumber: '', diameterMm: '0', retainedGrams: '0' },
                { meshNumber: '', diameterMm: '0', retainedGrams: '0' },
                { meshNumber: '', diameterMm: '0', retainedGrams: '0' },
                { meshNumber: '', diameterMm: '0', retainedGrams: '0' },
            ]
        }

        return initialData.measurements.map(m => ({
            meshNumber: m.meshNumber,
            diameterMm: m.diameterMm.toString(),
            retainedGrams: m.retainedGrams.toString(),
        }))
    }

    const [rows, setRows] = useState<Measurement[]>(getInitialMeasurements())
    const form = useForm<SandMeasurementFormData>({
        resolver: zodResolver(sandDataSchema),
        defaultValues: {
            sampleName: initialData?.sampleName || '',
            //   analysisDate: initialData?.sample_info.test_date || new Date().toISOString().split('T')[0],
            //   totalMass: initialData?.sample_info.total_mass_g?.toString() || '',
            //   company: initialData?.sample_info.company || '',
            measurements: rows,
        },
    })

    const addRow = () => {
        const newRow: Measurement = { meshNumber: '', diameterMm: '', retainedGrams: '' }
        setRows([...rows, newRow])
        const currentMeasurements = form.getValues('measurements')
        form.setValue('measurements', [...currentMeasurements, newRow])
    }

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
                <div className="grid gap-6 md:grid-cols-2">
                    {/* Sample Name */}
                    <FormField
                        control={form.control}
                        name="sampleName"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Sample Name*</FormLabel>
                                <FormControl>
                                    <Input placeholder="Sample name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    {/* Analysis Date */}
                    {/* <FormField
                        control={form.control}
                        name="analysisDate"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Analysis date</FormLabel>
                                <FormControl>
                                    <Input type="date" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

     
                    <FormField
                        control={form.control}
                        name="totalMass"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Total mass (g)*</FormLabel>
                                <FormControl>
                                    <Input placeholder="Mass in g or oz" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

       
                    <FormField
                        control={form.control}
                        name="company"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Company/club/venue</FormLabel>
                                <FormControl>
                                    <Input placeholder="Name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    /> */}
                </div>

                {/* Analysis Data Table */}
                <div className="space-y-4">
                    <h3 className="font-medium">Analysis Data</h3>
                    <div className="border rounded-lg overflow-hidden">
                        <table className="w-full">
                            <thead className="bg-slate-50">
                                <tr className="border-b">
                                    <th className="px-4 py-2 text-left font-medium">#</th>
                                    <th className="px-4 py-2 text-left font-medium">ø (mm)</th>
                                    <th className="px-4 py-2 text-left font-medium">Retained. (g)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map((_, index) => (
                                    <tr key={index} className="border-b">
                                        <td className="px-4 py-1.5">
                                            <FormField
                                                control={form.control}
                                                name={`measurements.${index}.meshNumber`}
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <Input className="h-8" placeholder="#" {...field} />
                                                        </FormControl>
                                                    </FormItem>
                                                )}
                                            />
                                        </td>
                                        <td className="px-4 py-1.5">
                                            <FormField
                                                control={form.control}
                                                name={`measurements.${index}.diameterMm`}
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <Input className="h-8" placeholder="0" {...field} />
                                                        </FormControl>
                                                    </FormItem>
                                                )}
                                            />
                                        </td>
                                        <td className="px-4 py-1.5">
                                            <FormField
                                                control={form.control}
                                                name={`measurements.${index}.retainedGrams`}
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <Input className="h-8" placeholder="0" {...field} />
                                                        </FormControl>
                                                    </FormItem>
                                                )}
                                            />
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan={3} className="px-4 py-1">
                                        <Button
                                            type="button"
                                            variant="ghost"
                                            className="w-full h-7 flex items-center justify-center hover:bg-slate-50"
                                            onClick={addRow}
                                        >
                                            <Plus className="mr-2 h-4 w-4" />
                                            Add row
                                        </Button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* Submit Button */}
                <div className="flex justify-start space-x-4 mt-8">
                    <Button
                        type="submit"
                        size="lg"
                        className="bg-[#0072CE] hover:bg-[#0072CE]/90"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Generating...' : 'Generate my report'}
                    </Button>
                </div>
            </form>
        </Form>
    )
} 