import { Control, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import {
    FormControl,
    FormField,
    FormItem,
} from '@/components/ui/form'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '@/components/ui/table'
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@/components/ui/tabs"
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "@/components/ui/alert-dialog"
import { Plus, Trash2 } from 'lucide-react'
import { SandAnalysisResult } from '@/data/sand-analysis'
import { useState } from 'react'

interface FormValues {
    isNew: boolean
    sampleName: string
    measurements: {
        meshNumber: string
        diameterMm: number
        retainedGrams: number
    }[]
}

interface SandMeasurementInputProps {
    control: Control<FormValues>
    activeTabId: string
    analyses: SandAnalysisResult[]
    onTabChange: (tabId: string) => void
    onAddNewSample: () => void
}

export function SandMeasurementInput({
    control,
    activeTabId,
    analyses,
    onTabChange,
    onAddNewSample
}: SandMeasurementInputProps) {
    const [pendingDeleteIndex, setPendingDeleteIndex] = useState<number | null>(null)
    const form = useFormContext<FormValues>()
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'measurements',
    })

    const formValues = useWatch({
        control,
        name: ['sampleName', 'isNew']
    })
    const sampleName = formValues[0]
    const isNew = formValues[1]

    const handleDeleteClick = (index: number) => {
        setPendingDeleteIndex(index)
    }

    const handleDeleteConfirm = () => {
        if (pendingDeleteIndex !== null) {
            remove(pendingDeleteIndex)
        }
        setPendingDeleteIndex(null)
    }

    const handleDeleteCancel = () => {
        setPendingDeleteIndex(null)
    }

    // Get the tab text - use form state for active tab, analysis name for others
    const getTabText = (analysis: SandAnalysisResult) => {
        if (analysis.id === activeTabId) {
            return sampleName || 'Untitled'
        }
        return analysis.sampleName || 'Untitled'
    }

    return (
        <>
            <AlertDialog open={pendingDeleteIndex !== null} onOpenChange={() => setPendingDeleteIndex(null)}>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>Delete Row</AlertDialogTitle>
                        <AlertDialogDescription>
                            Are you sure you want to delete this row? This action cannot be undone.
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel onClick={handleDeleteCancel}>Cancel</AlertDialogCancel>
                        <AlertDialogAction
                            onClick={handleDeleteConfirm}
                        >
                            Delete
                        </AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>

            <div className="rounded-lg bg-white border shadow-sm overflow-hidden">
                <Tabs value={activeTabId} onValueChange={onTabChange} className="w-full">
                    <div className="relative bg-sand">
                        <div className={`overflow-x-auto ${(!isNew && analyses.length < 5) ? 'mr-[100px]' : ''}`}>
                            <TabsList className="w-full justify-start bg-sand p-0 h-[46px] min-w-max" noBorder>
                                {analyses.map(analysis => (
                                    <TabsTrigger
                                        key={analysis.id}
                                        value={analysis.id}
                                        className="rounded-none border-b-2 border-transparent data-[state=active]:bg-white data-[state=active]:text-skyBlue px-4 h-full text-sm flex-1 min-w-[100px]"
                                    >
                                        {getTabText(analysis).slice(0,16)}
                                    </TabsTrigger>
                                ))}
                                {isNew && (
                                    <TabsTrigger
                                        value={activeTabId}
                                        className="rounded-none border-b-2 border-transparent data-[state=active]:bg-white data-[state=active]:text-skyBlue px-2 h-full text-sm flex-1 min-w-[100px]"
                                    >
                                        {(sampleName || 'Untitled').slice(0, 16)}
                                    </TabsTrigger>
                                )}
                            </TabsList>
                        </div>
                        {(!isNew && analyses.length < 5) && (
                            <div className="absolute right-0 top-0 h-full bg-sand">
                                <Button
                                    variant="ghost"
                                    className="text-skyBlue hover:text-skyBlue hover:bg-transparent px-6 h-full text-sm min-w-[100px]"
                                    onClick={onAddNewSample}
                                >
                                    Add <Plus className="h-4 w-4 ml-1" />
                                </Button>
                            </div>
                        )}
                    </div>

                    <TabsContent value={activeTabId} className="p-4">
                        <div className="space-y-4">
                            <div className="flex items-center space-x-2">
                                <label
                                    htmlFor="sample-name"
                                    className="text-sm text-muted-foreground min-w-[100px]"
                                >
                                    Sample name
                                </label>
                                <FormField
                                    control={control}
                                    name="sampleName"
                                    render={({ field }) => (
                                        <FormItem className="flex-1">
                                            <FormControl>
                                                <Input
                                                    {...field}
                                                    className="h-8 text-sm"
                                                />
                                            </FormControl>
                                        </FormItem>
                                    )}
                                />
                            </div>

                            <div className="space-y-4">
                                <div className="rounded-lg border bg-white shadow-sm">
                                    <Table>
                                        <TableHeader>
                                            <TableRow className="hover:bg-transparent border-0 px-4">
                                                <TableHead className="py-2 pr-2 text-sm font-medium text-muted-foreground">#</TableHead>
                                                <TableHead className="py-2 px-2 text-sm font-medium text-muted-foreground">Ø (mm)</TableHead>
                                                <TableHead className="py-2 pl-2 text-sm font-medium text-muted-foreground">Retained (g)</TableHead>
                                            </TableRow>
                                        </TableHeader>
                                        <TableBody>
                                            {fields.map((field, index) => (
                                                <TableRow
                                                    key={field.id}
                                                    className="hover:bg-transparent border-0 px-4 group relative"
                                                >
                                                    <TableCell className="py-2 pr-2">
                                                        <FormField
                                                            control={control}
                                                            name={`measurements.${index}.meshNumber`}
                                                            render={({ field }) => (
                                                                <FormItem className="space-y-0">
                                                                    <FormControl>
                                                                        <Input
                                                                            {...field}
                                                                            placeholder="#"
                                                                            className="h-[35px] px-3 bg-white border rounded-lg shadow-sm"
                                                                        />
                                                                    </FormControl>
                                                                </FormItem>
                                                            )}
                                                        />
                                                    </TableCell>
                                                    <TableCell className="py-2 px-2">
                                                        <FormField
                                                            control={control}
                                                            name={`measurements.${index}.diameterMm`}
                                                            render={({ field: { value, onChange, ...field } }) => (
                                                                <FormItem className="space-y-0">
                                                                    <FormControl>
                                                                        <Input
                                                                            {...field}
                                                                            value={value}
                                                                            onChange={e => {
                                                                                const normalized = e.target.value.replace(',', '.')
                                                                                onChange(normalized)
                                                                            }}
                                                                            className="h-[35px] px-3 bg-white border rounded-lg shadow-sm"
                                                                        />
                                                                    </FormControl>
                                                                </FormItem>
                                                            )}
                                                        />
                                                    </TableCell>
                                                    <TableCell className="py-2 pl-2">
                                                        <FormField
                                                            control={control}
                                                            name={`measurements.${index}.retainedGrams`}
                                                            render={({ field: { value, onChange, ...field } }) => (
                                                                <FormItem className="space-y-0">
                                                                    <FormControl>
                                                                        <div className="relative w-full">
                                                                            <Input
                                                                                {...field}
                                                                                value={value}
                                                                                onChange={e => {
                                                                                    const normalized = e.target.value.replace(',', '.')
                                                                                    onChange(normalized)
                                                                                }}
                                                                                className="h-[35px] px-3 bg-white border rounded-lg shadow-sm w-full transition-all group-hover:w-[90%]"
                                                                            />
                                                                            <Button
                                                                                type="button"
                                                                                variant="ghost"
                                                                                size="icon"
                                                                                tabIndex={-1}
                                                                                className="absolute right-[-13px] top-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 hover:bg-destructive/10 hover:text-destructive transition-opacity bg-sand rounded-lg h-6 w-6"
                                                                                onClick={() => handleDeleteClick(index)}
                                                                            >
                                                                                <Trash2 className="h-4 w-4" />
                                                                                <span className="sr-only">Delete row</span>
                                                                            </Button>
                                                                        </div>
                                                                    </FormControl>
                                                                </FormItem>
                                                            )}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                                <div className="rounded-lg border bg-white shadow-sm">
                                    <Button
                                        variant="ghost"
                                        className="w-full rounded-lg hover:bg-slate-50 text-sm h-8"
                                        onClick={() => append({
                                            meshNumber: '',
                                            diameterMm: 0,
                                            retainedGrams: 0,
                                        })}
                                    >
                                        Add row <Plus className="h-3.5 w-3.5 ml-1" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </TabsContent>
                </Tabs>
            </div>
        </>
    )
} 