import * as pdfjs from 'pdfjs-dist'
import { getDocument } from 'pdfjs-dist'

// Initialize PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

export interface ProcessedFile {
    base64: string[]
    mimeType: string
    originalName: string
    pageCount: number
}

export interface ConversionProgress {
    stage: 'calculating' | 'rendering'
    currentPage: number
    totalPages: number
    percentage: number
}

const MAX_PAGES = 5

/**
 * Debug function to download a base64 image as JPEG
 * @param base64Data Base64 encoded image data
 * @param filename Name for the downloaded file
 */
function downloadDebugImage(base64Data: string, filename: string) {
    // Remove data URL prefix if present
    const base64Clean = base64Data.includes('data:') ? base64Data : `data:image/jpeg;base64,${base64Data}`

    const link = document.createElement('a')
    link.href = base64Clean
    link.download = `${filename}_debug.jpg`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

/**
 * Applies image processing to optimize for AI readability
 * @param canvas Input canvas with the image
 * @returns Processed canvas
 */
function optimizeForAI(canvas: HTMLCanvasElement): HTMLCanvasElement {
    // const context = canvas.getContext('2d')
    // if (!context) {
    //     throw new Error('Could not create canvas context')
    // }

    // const imageData = context.getImageData(0, 0, canvas.width, canvas.height)
    // const data = imageData.data

    // // Convert to grayscale
    // for (let i = 0; i < data.length; i += 4) {
    //     const gray = 0.299 * data[i] + 0.587 * data[i + 1] + 0.114 * data[i + 2]
    //     data[i] = gray     // R
    //     data[i + 1] = gray // G
    //     data[i + 2] = gray // B
    //     // Alpha channel (data[i + 3]) remains unchanged
    // }

    // context.putImageData(imageData, 0, 0)
    return canvas
}

/**
 * Converts a PDF file to a JPEG image
 * @param file PDF file to convert
 * @param onProgress Callback for tracking conversion progress
 * @returns Base64 encoded JPEG image
 */
export async function convertPdfToImage(
    file: File,
    onProgress?: (progress: ConversionProgress) => void
): Promise<string[]> {
    // Load the PDF file
    const arrayBuffer = await file.arrayBuffer()
    const pdf = await getDocument({ data: arrayBuffer }).promise
    const numPages = pdf.numPages

    if (numPages > MAX_PAGES) {
        throw new Error(`PDF has too many pages. Maximum allowed is ${MAX_PAGES} pages.`)
    }

    const base64Pages: string[] = []
    const scale = 4 // Scale for quality

    // Process each page individually
    for (let i = 1; i <= numPages; i++) {
        if (onProgress) {
            onProgress({
                stage: 'rendering',
                currentPage: i,
                totalPages: numPages,
                percentage: (i / numPages) * 100
            })
        }

        const page = await pdf.getPage(i)
        const viewport = page.getViewport({ scale })

        // Create canvas for this page
        const canvas = document.createElement('canvas')
        canvas.width = viewport.width
        canvas.height = viewport.height

        const context = canvas.getContext('2d')
        if (!context) {
            throw new Error('Could not create canvas context')
        }

        // Clear canvas with white background
        context.fillStyle = '#FFFFFF'
        context.fillRect(0, 0, canvas.width, canvas.height)

        // Render PDF page to canvas
        const renderTask = page.render({
            canvasContext: context,
            viewport: viewport,
            background: 'transparent'
        })

        await renderTask.promise

        // Optimize the page for AI
        const optimizedCanvas = optimizeForAI(canvas)

        // Convert to base64 and store
        const base64Image = optimizedCanvas.toDataURL('image/jpeg', 1)
        base64Pages.push(base64Image.split(',')[1]) // Remove data URL prefix

        // Debug: Download the processed page
        //downloadDebugImage(`data:image/jpeg;base64,${base64Image.split(',')[1]}`, `${file.name}_page_${i}`)

        // Clean up
        canvas.remove()
        optimizedCanvas.remove()
    }

    await pdf.destroy()
    return base64Pages
}

/**
 * Converts any image to JPEG format
 * @param file Image file to convert
 * @returns Base64 encoded JPEG image
 */
async function convertImageToJpeg(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const img = new Image()
        const reader = new FileReader()

        reader.onload = (e) => {
            img.onload = () => {
                const canvas = document.createElement('canvas')
                canvas.width = img.width
                canvas.height = img.height

                const ctx = canvas.getContext('2d')
                if (!ctx) {
                    reject(new Error('Could not create canvas context'))
                    return
                }

                // Draw image with white background (for PNGs with transparency)
                ctx.fillStyle = '#FFFFFF'
                ctx.fillRect(0, 0, canvas.width, canvas.height)
                ctx.drawImage(img, 0, 0)

                // Apply AI optimization
                const optimizedCanvas = optimizeForAI(canvas)

                // Convert to JPEG
                const jpegBase64 = optimizedCanvas.toDataURL('image/jpeg', 1)

                // Debug: Download the converted image
                //downloadDebugImage(jpegBase64, file.name)

                canvas.remove()
                resolve(jpegBase64)
            }

            img.onerror = () => reject(new Error('Failed to load image'))
            img.src = e.target?.result as string
        }

        reader.onerror = () => reject(new Error('Failed to read file'))
        reader.readAsDataURL(file)
    })
}

/**
 * Processes a file (PDF or Image) for AI analysis
 * @param file File to process
 * @param onProgress Callback for tracking conversion progress
 * @returns Processed file data including base64 and metadata
 */
export async function processFileForAnalysis(
    file: File
): Promise<ProcessedFile> {
    try {
        // Validate file size (10MB)
        const MAX_SIZE = 10 * 1024 * 1024
        if (file.size > MAX_SIZE) {
            throw new Error('File size exceeds 10MB limit')
        }

        let base64: string[]
        let mimeType = 'image/jpeg' // We'll always convert to JPEG
        let pageCount = 1

        if (file.type === 'application/pdf') {
            // Convert PDF to images
            base64 = await convertPdfToImage(file)
            pageCount = base64.length
        } else if (file.type.startsWith('image/')) {
            // Handle single image
            const singleImage = await convertImageToJpeg(file)
            base64 = [singleImage.split(',')[1]] // Remove data URL prefix and wrap in array
        } else {
            throw new Error('Unsupported file type. Please upload a PDF or image file.')
        }

        return {
            base64,
            mimeType,
            originalName: file.name,
            pageCount
        }
    } catch (error) {
        console.error('Error processing file:', error)
        throw error
    }
}

/**
 * Validates a file before processing
 * @param file File to validate
 * @returns True if file is valid
 */
export function validateFile(file: File): boolean {
    const validTypes = ['application/pdf', 'image/jpeg', 'image/png', 'image/jpg']
    const MAX_SIZE = 10 * 1024 * 1024 // 10MB

    if (!validTypes.includes(file.type)) {
        throw new Error('Invalid file type. Please upload a PDF or image file (JPEG, PNG).')
    }

    if (file.size > MAX_SIZE) {
        throw new Error('File size exceeds 10MB limit.')
    }

    return true
} 