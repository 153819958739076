import { useTranslation } from 'react-i18next'
import { DateTimeParam, StringParam, BooleanParam, useQueryParam } from 'use-query-params'
import { useState, useEffect } from 'react'
import { Button } from '@/components/ui/button'
import { BeakerIcon, Brain } from 'lucide-react'
import { cn } from '@/lib/utils'
import TimelineView from '@/components/v2/timeline/TimelineView'
import DeviceImeiAutocomplete from '@/components/v2/selects/DeviceImeiAutocomplete'
import TableDateSelect from '@/components/v2/selects/TableDateSelect'
import { Alert, AlertDescription } from '@/components/ui/alert'

export default function DeviceTimelinePage() {
  const { t } = useTranslation(['admin'])
  const [imei, setImei] = useQueryParam('imei', StringParam)
  const [from] = useQueryParam('from', DateTimeParam)
  const [to] = useQueryParam('to', DateTimeParam)
  const [analyze, setAnalyze] = useQueryParam('analyze', BooleanParam)

  const [searchParams, setSearchParams] = useState({
    imei: imei || '',
    from,
    to,
    analyze: analyze || false,
    enabled: false
  })

  const [pendingSearch, setPendingSearch] = useState({
    imei: imei || '',
    from,
    to,
    analyze: analyze || false
  })

  useEffect(() => {
    setPendingSearch({
      imei: imei || '',
      from,
      to,
      analyze: analyze || false
    })
  }, [imei, from, to, analyze])

  const hasChanges = JSON.stringify({ ...searchParams, enabled: undefined }) !== JSON.stringify(pendingSearch)

  const handleSearch = (withAnalysis: boolean) => {
    setAnalyze(withAnalysis)
    const newParams = {
      ...pendingSearch,
      analyze: withAnalysis,
      enabled: true
    }
    setSearchParams(newParams)
  }

  return (
    <div className="px-4">
      <Alert className="bg-blue-50/50 border-blue-200/60 mt-4">
        <BeakerIcon className="h-4 w-4 text-blue-600" />
        <AlertDescription className="text-blue-700">
          {t('timeline.beta_notice', 'This page is in beta. Please report any issues or incorrect data.')}
        </AlertDescription>
      </Alert>
      <div className="relative my-8">
        <h2 className="text-3xl">{t('timeline.title')}</h2>
      </div>

      <div className="my-4 flex flex-wrap gap-4 md:flex-nowrap">
        <div className="min-w-full sm:min-w-[200px]">
          <DeviceImeiAutocomplete
            imei={imei || ''}
            onSelect={(val) => setImei(val)}
          />
        </div>
        <div className="min-w-full sm:min-w-[200px]">
          <TableDateSelect />
        </div>
        <div className="flex gap-2 w-full sm:w-auto">
          <Button
            onClick={() => handleSearch(false)}
            disabled={!hasChanges}
            className="flex-1"
          >
            Search
          </Button>
          <Button
            onClick={() => handleSearch(true)}
            disabled={!hasChanges}
            variant="secondary"
            className={cn(
              "shrink-0",
              "bg-blue-50/80 hover:bg-blue-100/80",
              "text-blue-700 hover:text-blue-800",
              "border border-blue-200/60"
            )}
          >
            <Brain className="mr-2 h-4 w-4" />
            AI Analysis
          </Button>
        </div>
      </div>

      <TimelineView
        imei={searchParams.imei}
        from={searchParams.from}
        to={searchParams.to}
        analyze={searchParams.analyze}
        enabled={searchParams.enabled}
      />
    </div>
  )
} 