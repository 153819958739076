import { useQuery } from '@tanstack/react-query'
import type { DeviceTimelineResponse } from '@/data/admin/device-timeline'
import { getDeviceTimeline, TimelineError } from '@/data/admin/device-timeline'

type TimelineQueryKey = ['admin-device-timeline', string | null | undefined, Date | null | undefined, Date | null | undefined, boolean | undefined]

export function useAdminDeviceTimeline(
  imei?: string | null,
  from?: Date | null,
  to?: Date | null,
  analyze?: boolean,
  enabled = false
) {
  return useQuery<DeviceTimelineResponse, TimelineError, DeviceTimelineResponse, TimelineQueryKey>({
    queryKey: ['admin-device-timeline', imei, from, to, analyze],
    queryFn: () => getDeviceTimeline(imei!, from || undefined, to || undefined, analyze),
    enabled: enabled && !!imei,
    staleTime: Infinity,
    gcTime: 30 * 60 * 1000,
    retry: false
  })
}

// Re-export the TimelineError type for components to use
export type { TimelineError } 