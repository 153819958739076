import { useAdminDeviceTimeline } from '@/hooks/useAdminDeviceTimeline'
import TimelineStatusOverview from './TimelineStatusOverview'
import TimelinePeriod from './TimelinePeriod'
import TimelineLoading from './TimelineLoading'
import type { TimelineItem, TimelinePeriod as TimelinePeriodType, TransitionEvent } from '@/data/admin/device-timeline'
import { AlertCircle, BeakerIcon } from 'lucide-react'
import { Alert, AlertDescription } from '@/components/ui/alert'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { cn } from '@/lib/utils'
import TimelineAnalysis from './TimelineAnalysis'
import { TimelineError } from '@/hooks/useAdminDeviceTimeline'

interface TimelineViewProps {
  imei?: string | null
  from?: Date | null
  to?: Date | null
  analyze?: boolean
  enabled?: boolean
}

export default function TimelineView({ imei, from, to, analyze = false, enabled = false }: TimelineViewProps) {
  const { t } = useTranslation(['admin'])
  const { data, isLoading, error } = useAdminDeviceTimeline(imei, from, to, analyze, enabled)

  const formatTransitionMessage = (message: string) => {
    if (message.includes("Active Section changed to")) {
      return `Section → ${message.split(" ").pop()}`
    }
    return message
  }

  if (!imei) {
    return (
      <Alert className="border-slate-200/60 bg-slate-50/50">
        <AlertCircle className="h-4 w-4 text-slate-600" />
        <AlertDescription className="text-slate-600">
          {t('timeline.select_device')}
        </AlertDescription>
      </Alert>
    )
  }

  if (isLoading) {
    return <TimelineLoading isAnalyzing={analyze} />
  }

  if (error) {
    const problem = (error as TimelineError).response?.data
    const errorTitle = problem?.title || t('timeline.load_error')
    const errorDetail = problem?.detail || error.message
    const isAIError = errorTitle.includes('AI Analysis') || errorDetail.includes('AI Analysis')

    return (
      <Alert variant="destructive" className="border-rose-200/60 bg-rose-50/50">
        <AlertCircle className="h-4 w-4" />
        <AlertDescription className="flex flex-col gap-1">
          <span className="font-medium">{errorTitle}</span>
          <span className="text-sm opacity-90">{errorDetail}</span>
        </AlertDescription>
      </Alert>
    )
  }

  if (!data?.timeline.length) {
    return (
      <Alert className="border-slate-200/60 bg-slate-50/50">
        <AlertCircle className="h-4 w-4 text-slate-600" />
        <AlertDescription className="text-slate-600">
          {t('timeline.no_data')}
        </AlertDescription>
      </Alert>
    )
  }

  return (
    <div className="space-y-8 mt-8">
      <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 xl:grid-cols-4">
        {data?.statusOverview && (
          <TimelineStatusOverview overview={data.statusOverview} />
        )}
      </div>

      {data?.analysis && (
        <div className="grid gap-6 grid-cols-1 md:grid-cols-3">
          <TimelineAnalysis analysis={data.analysis} />
        </div>
      )}

      <div className="space-y-4">
        {data?.timeline.map((item: TimelineItem, index: number) => (
          <div key={`${item.startTime}-${item.type}-${item.endTime}-${index}`}>
            {item.type === 'Period' ? (
              <TimelinePeriod period={item.data as TimelinePeriodType} />
            ) : (
              <div className="my-2 flex items-center gap-2">
                <div className="rounded-md bg-white/80 px-3 py-1.5 text-sm flex items-center gap-2 border border-slate-200/60 shadow-sm">
                  <div className="flex items-center gap-1.5">
                    {(item.data as TransitionEvent).type === 'SystemChange' ? (
                      <span className={cn(
                        "px-2 py-0.5 rounded-full text-xs font-medium",
                        (item.data as TransitionEvent).changes?.Event === 'Offline'
                          ? "bg-rose-50/70 text-rose-700"
                          : "bg-emerald-50/70 text-emerald-700"
                      )}>
                        {(item.data as TransitionEvent).description}
                      </span>
                    ) : (
                      <span className="bg-blue-50/70 text-blue-700 px-2 py-0.5 rounded-full text-xs font-medium">
                        {formatTransitionMessage((item.data as TransitionEvent).description)}
                      </span>
                    )}
                  </div>

                  <span className="text-slate-400 text-xs font-mono">
                    {new Date(item.startTime).toLocaleTimeString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false
                    })}
                  </span>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
} 