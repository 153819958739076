import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StringParam, useQueryParam } from 'use-query-params'
import { useQueryClient } from '@tanstack/react-query'

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import DeviceImeiAutocomplete from '@/components/v2/selects/DeviceImeiAutocomplete'
import { createDiagnosticSchedule, updateDiagnosticSchedule } from '@/data/admin/diagnostic'
import { failureToastClassNames, successfulToastClassNames } from '@/utils/toastHelpers'
import useAdminDiagnosticSchedules from '@/hooks/useAdminDiagnosticSchedules'
import { cn } from '@/lib/utils'

export default function CreateScheduleModal() {
  const { t } = useTranslation(['admin'])
  const [id, setId] = useQueryParam('id', StringParam)
  const [imei, setImei] = useState('')
  const [intervalMinutes, setIntervalMinutes] = useState('15')
  const [endDateTime, setEndDateTime] = useState(
    new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString().slice(0, 16)
  )
  const { toast } = useToast()
  const queryClient = useQueryClient()

  // Add validation state
  const [intervalError, setIntervalError] = useState<string>()

  // Fetch existing schedule data if editing
  const { data } = useAdminDiagnosticSchedules(
    undefined,
    1,
    0,
    undefined
  )
  const existingSchedule = data?.diagnosticSchedules.find(
    s => s.id.toString() === id
  )

  // Pre-fill form when editing
  useEffect(() => {
    if (existingSchedule && id !== 'new-schedule') {
      setImei(existingSchedule.imei)
      setIntervalMinutes(existingSchedule.intervalMinutes.toString())
      setEndDateTime(new Date(existingSchedule.endDateTime).toISOString().slice(0, 16))
    }
  }, [existingSchedule, id])

  const handleClose = () => {
    setId(null)
    setImei('')
    setIntervalMinutes('15')
    setEndDateTime(new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString().slice(0, 16))
    setIntervalError(undefined)
  }

  const validateInterval = (value: string) => {
    const interval = parseInt(value)
    if (isNaN(interval) || interval < 10) {
      return 'Interval must be at least 10 minutes'
    }
    return undefined
  }

  const handleSubmit = async () => {
    if (!imei || !intervalMinutes || !endDateTime) {
      toast({
        title: 'Please fill in all fields',
        className: failureToastClassNames,
      })
      return
    }

    const error = validateInterval(intervalMinutes)
    if (error) {
      setIntervalError(error)
      return
    }

    try {
      const data = {
        imei,
        intervalMinutes: parseInt(intervalMinutes),
        startDateTime: new Date(),
        endDateTime: new Date(endDateTime),
      }

      if (id && id !== 'new-schedule') {
        await updateDiagnosticSchedule(parseInt(id), data)
      } else {
        await createDiagnosticSchedule(data)
      }

      queryClient.invalidateQueries({ queryKey: ['admin-diagnostic-schedules'] })
      toast({
        title: `Schedule ${id ? 'updated' : 'created'} successfully`,
        className: successfulToastClassNames,
      })
      handleClose()
    } catch (err) {
      toast({
        title: `Failed to ${id ? 'update' : 'create'} schedule`,
        className: failureToastClassNames,
      })
    }
  }

  const isEditing = id && id !== 'new-schedule'
  const isValid = imei && intervalMinutes && endDateTime && !intervalError

  return (
    <Dialog open={!!id} onOpenChange={() => handleClose()}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>
            {isEditing ? 'Edit Schedule' : 'Create Schedule'}
          </DialogTitle>
        </DialogHeader>

        <div className="grid gap-4 py-4">
          <div className="grid gap-2">
            <Label htmlFor="imei">Device</Label>
            {isEditing ? (
              <div className="p-2 bg-slate-50 rounded-md">
                {imei}
              </div>
            ) : (
              <DeviceImeiAutocomplete
                imei={imei}
                onSelect={(val) => setImei(val || '')}
              />
            )}
          </div>

          <div className="grid gap-2">
            <Label htmlFor="interval">Interval (minutes)</Label>
            <Input
              id="interval"
              type="number"
              min="10"
              value={intervalMinutes}
              onChange={(e) => {
                setIntervalMinutes(e.target.value)
                setIntervalError(validateInterval(e.target.value))
              }}
              className={cn(intervalError && "border-red-500")}
            />
            {intervalError && (
              <span className="text-sm text-red-500">
                {intervalError}
              </span>
            )}
          </div>

          <div className="grid gap-2">
            <Label htmlFor="endDate">End Date</Label>
            <Input
              id="endDate"
              type="datetime-local"
              value={endDateTime}
              onChange={(e) => setEndDateTime(e.target.value)}
            />
          </div>
        </div>

        <div className="flex justify-end gap-2">
          <Button variant="outline" onClick={handleClose}>
            Cancel
          </Button>
          <Button 
            onClick={handleSubmit}
            disabled={!isValid}
          >
            {isEditing ? 'Update' : 'Create'}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
} 