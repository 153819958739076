import { CheckCircle, AlertTriangle, Settings, WifiOff, Gauge, Timer, LucideIcon } from 'lucide-react'
import type { StatusOverview } from '@/data/admin/device-timeline'
import { useTranslation } from 'react-i18next'
import { Card } from '@/components/ui/card'
import { cn } from '@/lib/utils'

interface TimelineStatusOverviewProps {
  overview?: StatusOverview
}

interface StatDetail {
  label: string
  value: number
}

interface StatItem {
  icon: LucideIcon
  label: string
  value: string | number
  subValue?: string
  variant: 'success' | 'warning' | 'destructive' | 'default'
  customIndicator?: React.ReactNode
  expandable?: boolean
  details?: StatDetail[]
}

interface StatGroup {
  label: string
  gradient: string
  items: StatItem[]
}

const SignalStrengthIndicator = ({ strength }: { strength: number }) => {
  const bars = [
    { height: 'h-2', threshold: -100 },
    { height: 'h-3', threshold: -90 },
    { height: 'h-4', threshold: -80 },
    { height: 'h-5', threshold: -70 }
  ]

  return (
    <div className="flex items-end ml-1 gap-0.5 h-5">
      {bars.map((bar, i) => (
        <div
          key={i}
          className={cn(
            'w-1 rounded-sm transition-all duration-300',
            bar.height,
            strength >= bar.threshold 
              ? 'bg-green-500' 
              : 'bg-gray-200'
          )}
        />
      ))}
    </div>
  )
}

export default function TimelineStatusOverview({ overview }: TimelineStatusOverviewProps) {
  const { t } = useTranslation(['admin'])
  
  if (!overview) return null

  const stats: StatGroup[] = [
    {
      label: 'System Health',
      gradient: 'from-blue-50/80 via-indigo-50/60 to-blue-50/80 backdrop-blur-sm',
      items: [
        {
          icon: WifiOff,
          label: 'Offline Events',
          value: overview.offlineEvents,
          subValue: overview.totalOfflineTime > 0 ? `${overview.totalOfflineTime}m total` : undefined,
          variant: overview.offlineEvents > 0 ? 'warning' : 'success'
        },
        {
          icon: AlertTriangle,
          label: 'Reboots',
          value: overview.rebootEvents,
          variant: overview.rebootEvents > 0 ? 'warning' : 'success'
        }
      ]
    },
    {
      label: 'Operations',
      gradient: 'from-green-50 to-emerald-50',
      items: [
        {
          icon: CheckCircle,
          label: 'Complete Cycles',
          value: overview.completeCycles,
          variant: 'success'
        },
        {
          icon: AlertTriangle,
          label: 'Interrupted',
          value: overview.interruptedCycles,
          variant: overview.interruptedCycles > 0 ? 'warning' : 'success'
        },
        {
          icon: Timer,
          label: 'Section Changes',
          value: overview.sectionChanges,
          variant: 'default'
        }
      ]
    },
    {
      label: 'Errors',
      gradient: 'from-red-50 to-orange-50',
      items: [
        {
          icon: AlertTriangle,
          label: 'Total Errors',
          value: overview.totalErrors,
          variant: overview.totalErrors > 0 ? 'destructive' : 'success',
          expandable: true,
          details: Object.entries(overview.errorCounts).map(([key, count]) => ({
            label: key,
            value: count
          }))
        }
      ]
    },
    {
      label: 'Signal Quality',
      gradient: 'from-purple-50 to-pink-50',
      items: [
        {
          icon: Gauge,
          label: 'Signal Strength',
          value: `${overview.averageSignalStrength} avg`,
          variant: overview.signalLossEvents > 0 ? 'warning' : 'success',
          customIndicator: <SignalStrengthIndicator strength={overview.averageSignalStrength} />
        }
      ]
    }
  ]

  return (
    <>
      {stats.map((group) => (
        <Card key={group.label} className={cn(
          "p-6 border border-slate-200/60 shadow-lg bg-gradient-to-br h-full flex flex-col",
          "hover:shadow-xl transition-shadow duration-200",
          "backdrop-blur-sm ring-1 ring-slate-200/60",
          group.gradient
        )}>
          <h3 className="mb-5 text-sm font-medium text-slate-600 tracking-wide">{group.label}</h3>
          <div className="space-y-4 flex-1">
            {group.items.map((item) => (
              <div key={item.label} className="flex flex-col justify-between">
                <div className="flex items-center justify-between group">
                  <div className="flex items-center gap-3">
                    <item.icon 
                      className={cn(
                        "h-4 w-4 transition-colors",
                        item.variant === 'success' && "text-emerald-500",
                        item.variant === 'warning' && "text-amber-500",
                        item.variant === 'destructive' && "text-rose-500",
                        item.variant === 'default' && "text-blue-500"
                      )} 
                    />
                    <span className="text-sm text-slate-600 font-medium">{item.label}</span>
                  </div>
                  <div className="flex items-center gap-4">
                    {item.customIndicator}
                    <div className="text-right">
                      <div className={cn(
                        "flex items-baseline gap-1 justify-end",
                        item.variant === 'success' && "text-emerald-600",
                        item.variant === 'warning' && "text-amber-600",
                        item.variant === 'destructive' && "text-rose-600",
                        item.variant === 'default' && "text-slate-900"
                      )}>
                        <span className="text-base font-semibold tracking-tight">{item.value}</span>
                      </div>
                      {item.subValue && (
                        <div className="text-xs text-slate-500 font-medium">
                          {item.subValue}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {item.expandable && item.details && (
                  <div className="mt-3 space-y-1.5 pl-7 border-l-2 border-slate-100">
                    {item.details.map((detail) => (
                      <div key={detail.label} className="flex justify-between text-sm">
                        <span className="text-slate-500">{detail.label}</span>
                        <span className="font-medium text-slate-700">{detail.value}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </Card>
      ))}
    </>
  )
} 