import * as Sentry from '@sentry/react'
import { z } from 'zod'

import { axiosClient } from '@/lib/axios'

// Types for API responses
export interface SandStandard {
    id: string
    name: string
    description: string
    isActive: boolean
    minBoundary: SandMeasurement[]
    maxBoundary: SandMeasurement[]
    createdAt: string
    updatedAt: string
}

export interface SandMeasurement {
    meshNumber: string
    diameterMm: number
    retainedGrams: number
}

// Types for form data
export interface CreateUpdateSandStandard {
    name: string
    description: string
    isActive: boolean
    minBoundary: SandMeasurement[]
    maxBoundary: SandMeasurement[]
}

// Validation Schemas
export const SandMeasurementSchema = z.object({
    meshNumber: z.string(),
    diameterMm: z.preprocess(
        (val) => (typeof val === 'string' ? parseFloat(val) : val),
        z.number().min(0.01).max(10),
    ),
    retainedGrams: z.preprocess(
        (val) => (typeof val === 'string' ? parseFloat(val) : val),
        z.number().min(0),
    ),
})

export const SandStandardSchema = z.object({
    name: z.string().min(1, 'Name is required'),
    description: z.string(),
    isActive: z.boolean(),
    minBoundary: z.array(SandMeasurementSchema),
    maxBoundary: z.array(SandMeasurementSchema),
})


// API Integration
const BASE_URL = '/admin/sand-standards'

export const getSandStandards = async (abortSignal: AbortSignal) => {
    try {
        const response = await axiosClient.get<SandStandard[]>(BASE_URL, {
            signal: abortSignal,
        })
        return response.data
    } catch (err) {
        Sentry.captureException(err)
        throw err
    }
}

export const getSandStandard = async (id: string) => {
    try {
        const response = await axiosClient.get<SandStandard>(`${BASE_URL}/${id}`)

        return response.data
    } catch (err) {
        Sentry.captureException(err)
        throw err
    }
}

export const createSandStandard = async (data: CreateUpdateSandStandard) => {
    try {
        const response = await axiosClient.post<SandStandard>(BASE_URL, data)
        return response.data
    } catch (err) {
        Sentry.captureException(err)
        throw err
    }
}

export const updateSandStandard = async (
    id: string,
    data: Partial<CreateUpdateSandStandard>,
) => {
    try {
        const response = await axiosClient.put<SandStandard>(
            `${BASE_URL}/${id}`,
            data,
        )
        return response.data
    } catch (err) {
        Sentry.captureException(err)
        throw err
    }
}

export const deleteSandStandard = async (id: string) => {
    try {
        await axiosClient.delete(`${BASE_URL}/${id}`)
    } catch (err) {
        Sentry.captureException(err)
        throw err
    }
}

export const toggleSandStandardActive = async (id: string) => {
    try {
        const response = await axiosClient.post<SandStandard>(
            `${BASE_URL}/${id}/toggle-active`,
        )
        return response.data
    } catch (err) {
        Sentry.captureException(err)
        throw err
    }
} 