import { Pencil } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { Link, useNavigate } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { useToast } from '@/components/ui/use-toast'
import { getSandStandards } from '@/data/sand-standards'

export function SandStandardsList() {
  const { t } = useTranslation(['admin', 'common'])
  const { toast } = useToast()
  const navigate = useNavigate()
  const abortController = new AbortController()

  const { data: standards, isLoading } = useQuery({
    queryKey: ['sand-standards'],
    queryFn: () => getSandStandards(abortController.signal),
  })

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <div className="space-y-4">
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>{t('sand_standards.name')}</TableHead>
              <TableHead>{t('sand_standards.description')}</TableHead>
              <TableHead>{t('sand_standards.status')}</TableHead>
              <TableHead className="w-[100px]">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {standards?.map((standard) => (
              <TableRow 
                key={standard.id} 
                className="group hover:bg-muted/50 cursor-pointer"
                onClick={() => navigate(`/admin/sand-standards/${standard.id}`)}
              >
                <TableCell className="font-medium">
                  {standard.name}
                </TableCell>
                <TableCell>
                  {standard.description}
                </TableCell>
                <TableCell>
                  {standard.isActive ? 'Active' : 'Inactive'}
                </TableCell>
                <TableCell>
                  <Button
                    variant="ghost"
                    className="h-8 w-8 p-0"
                    asChild
                  >
                    <Link 
                      to={`/admin/sand-standards/${standard.id}`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Pencil className="h-4 w-4" />
                      <span className="sr-only">{t('common.edit')}</span>
                    </Link>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
} 