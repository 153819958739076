import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { SandStandardsList } from '@/components/admin/sand-standards/SandStandardsList'
import { Button } from '@/components/ui/button'

export default function SandStandardsPage() {
  const { t } = useTranslation(['admin'])

  return (
    <div className="container mx-auto py-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">{t('sand_standards.title')}</h1>
        <Button asChild>
          <Link to="/admin/sand-standards/new">
            {t('sand_standards.add_new')}
          </Link>
        </Button>
      </div>
      <SandStandardsList />
    </div>
  )
} 