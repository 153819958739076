import { type TimelinePeriod as TimelinePeriodType } from '@/data/admin/device-timeline'
import { dateWithTimeInIsoFormat } from '@/utils/dateHelpers'
import { Card } from '@/components/ui/card'
import { useTranslation } from 'react-i18next'
import { ArrowRight, ChevronDown, Signal, Thermometer } from 'lucide-react'
import { cn } from '@/lib/utils'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { useState } from 'react'

interface TimelinePeriodProps {
  period: TimelinePeriodType
}

export default function TimelinePeriod({ period }: TimelinePeriodProps) {
  const { t } = useTranslation(['admin'])
  const { summary, actions, transition } = period
  const [isExpanded, setIsExpanded] = useState(false)

  const badgeStyles: Record<string, { bg: string, text: string }> = {
    diagnostic: { bg: 'bg-blue-50/80', text: 'text-blue-700' },
    metadata: { bg: 'bg-purple-50/80', text: 'text-purple-700' },
    data: { bg: 'bg-emerald-50/80', text: 'text-emerald-700' },
    warning: { bg: 'bg-amber-50/80', text: 'text-amber-700' },
    signal: { bg: 'bg-slate-50/80', text: 'text-slate-700' }
  }

  return (
    <Card className="overflow-hidden border-slate-200/60 shadow-md hover:shadow-lg transition-shadow duration-200">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full text-left"
      >
        <div className="flex items-center justify-between border-b border-slate-200/60 p-4 bg-white/50">
          {/* Left side - Transition description */}
          <div className="flex-1 flex items-center gap-3">
            <h3 className="text-base font-medium text-slate-800">
              {transition?.description}
            </h3>

            {/* Duration and Signal Badge Group */}
            <div className="flex items-center gap-2">
              <div className="text-sm text-slate-500 font-mono tracking-tight">
                {summary.duration}
              </div>
            </div>

            {/* Action Badges */}
            <div className="flex items-center gap-2 text-xs">
              {period.badges.map((badge) => (
                <TooltipProvider key={badge.type}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <span className={cn(
                        "px-2 py-0.5 rounded-full text-xs font-medium",
                        badgeStyles[badge.type]?.bg ?? 'bg-slate-50/80',
                        badgeStyles[badge.type]?.text ?? 'text-slate-700',
                        badge.type === 'signal' && 'text-slate-500'
                      )}>
                        {badge.type === 'signal' ? (
                          <div className="flex items-center gap-1.5">
                            <Signal className={cn(
                              "h-3 w-3",
                              !badge.metadata?.average && "text-slate-400"
                            )} />
                            <span>{badge.metadata?.average}</span>
                          </div>
                        ) : (
                          `${badge.count} ${badge.label}`
                        )}
                      </span>
                    </TooltipTrigger>
                    {badge.metadata && (
                      <TooltipContent className="bg-white/95 backdrop-blur-sm border-slate-200/60">
                        {Object.entries(badge.metadata ?? {}).map(([key, value]) => (
                          <div key={key} className="flex justify-between gap-4">
                            <span className="text-slate-500">{key}:</span>
                            <span className="font-medium">{String(value)}</span>
                          </div>
                        ))}
                      </TooltipContent>
                    )}
                  </Tooltip>
                </TooltipProvider>
              ))}
            </div>
          </div>

          {/* Right side - Time info */}
          <div className="flex items-center gap-3 pl-6">
            <div className="flex items-center gap-2 font-mono text-slate-500 text-xs">
              <time dateTime={period.startTime}>
                {dateWithTimeInIsoFormat(new Date(period.startTime))}
              </time>
              <ArrowRight className="h-3 w-3 text-slate-300" />
              <time dateTime={period.endTime}>
                {dateWithTimeInIsoFormat(new Date(period.endTime))}
              </time>
            </div>
            <ChevronDown className={cn(
              "h-4 w-4 transition-transform text-slate-400",
              isExpanded && "rotate-180"
            )} />
          </div>
        </div>
      </button>

      {isExpanded && (
        <div className="grid grid-cols-1 divide-y divide-slate-200/60 lg:grid-cols-2 lg:divide-x lg:divide-y-0">
          {/* Data Column */}
          <div className="p-6 bg-white/50">
            <div className="space-y-6">
              <StatusDistribution summary={summary} />
              <div className="grid grid-cols-2 gap-4">
                <SectionStats
                  title={t('timeline.left_section')}
                  stats={summary.leftSection}
                  isActive={summary.activeSection === 'Left'}
                />
                <SectionStats
                  title={t('timeline.right_section')}
                  stats={summary.rightSection}
                  isActive={summary.activeSection === 'Right'}
                />
              </div>
            </div>
          </div>

          {/* Actions Column */}
          <div className="space-y-2 p-4 bg-slate-50/50">
            {actions.map((action) => (
              <TimelineActionItem key={action.timestamp} action={action} />
            ))}
          </div>
        </div>
      )}
    </Card>
  )
}

interface StatusData {
  label: string
  percentage: number
  color: string
}

interface WaterLevelChangeProps {
  min: number
  max: number
  unit?: string
  decimals?: number
}

interface ValueWithRangeProps {
  start: number
  end: number
  average: number
  unit: string
  decimals?: number
}

interface SectionStatsProps {
  title: string
  stats: TimelinePeriodType['summary']['leftSection'] | TimelinePeriodType['summary']['rightSection']
  isActive: boolean
}

interface TimelineActionItemProps {
  action: TimelinePeriodType['actions'][0]
}

function StatusDistribution({ summary }: { summary: TimelinePeriodType['summary'] }) {
  const { t } = useTranslation(['admin'])

  const statuses: StatusData[] = [
    {
      label: t('timeline.normal'),
      percentage: summary.normalPercentage,
      color: 'bg-emerald-500/90'
    },
    {
      label: t('timeline.low_water'),
      percentage: summary.lowWaterPercentage,
      color: 'bg-amber-500/90'
    },
    {
      label: t('timeline.pending'),
      percentage: summary.pendingPercentage,
      color: 'bg-slate-300/90'
    }
  ].filter(status => status.percentage > 0)

  return (
    <div className="space-y-4">
      <h3 className="text-base font-medium text-slate-800">
        {t('timeline.status_distribution')}
      </h3>

      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <div className="h-3 w-full overflow-hidden rounded-full bg-slate-100/50 ring-1 ring-slate-200/60">
              <div className="flex h-full">
                {statuses.map((status, index) => (
                  <div
                    key={status.label}
                    className={cn(
                      "transition-all duration-300",
                      status.color,
                      index === 0 && "rounded-l-full",
                      index === statuses.length - 1 && "rounded-r-full"
                    )}
                    style={{ width: `${status.percentage}%` }}
                  />
                ))}
              </div>
            </div>
          </TooltipTrigger>
          <TooltipContent className="bg-white/95 backdrop-blur-sm border-slate-200/60">
            <div className="space-y-2">
              {statuses.map(status => (
                <div key={status.label} className="flex items-center gap-2">
                  <div className={cn("h-3 w-3 rounded-full", status.color)} />
                  <span className="text-sm text-slate-600">
                    {status.label}: <span className="font-mono font-medium text-slate-800">{status.percentage}%</span>
                  </span>
                </div>
              ))}
            </div>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <div className="flex items-center gap-4 text-sm text-slate-600">
        {statuses.map(status => (
          <div key={status.label} className="flex items-center gap-2">
            <div className={cn("h-2 w-2 rounded-full", status.color)} />
            <span className="font-medium">{status.label}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

function SectionStats({ title, stats, isActive }: SectionStatsProps) {
  const { t } = useTranslation(['admin'])

  const WaterLevelChange = ({ min, max, unit = '', decimals = 1 }: WaterLevelChangeProps) => {
    const change = max - min
    const color = change > 0 ? 'text-emerald-600' : change < 0 ? 'text-rose-600' : 'text-slate-600'

    return (
      <div className="flex items-center gap-1 font-mono text-sm">
        <span className="text-slate-700">{min.toFixed(decimals)}</span>
        <ArrowRight className="h-3 w-3 text-slate-300" />
        <span className={color}>{max.toFixed(decimals)}</span>
        <span className="text-slate-500 ml-0.5">{unit}</span>
      </div>
    )
  }

  const ValueWithRange = ({
    start,
    end,
    average,
    unit = '',
    decimals = 1
  }: ValueWithRangeProps) => {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <div className="flex items-center gap-1 font-mono text-sm">
              <span className="text-slate-700">{average.toFixed(decimals)}</span>
              <span className="text-slate-500">{unit}</span>
              <span className="text-xs text-slate-400">(avg)</span>
            </div>
          </TooltipTrigger>
          <TooltipContent className="bg-white/95 backdrop-blur-sm border-slate-200/60">
            <div className="space-y-1.5 text-sm">
              <div className="text-slate-600">Start: <span className="font-medium text-slate-700">{start.toFixed(decimals)}{unit}</span></div>
              <div className="text-slate-600">End: <span className="font-medium text-slate-700">{end.toFixed(decimals)}{unit}</span></div>
            </div>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    )
  }

  return (
    <div className={cn(
      "rounded-lg border border-slate-200/60 p-4 space-y-4",
      "bg-white/50 backdrop-blur-sm",
      isActive && "ring-2 ring-blue-500/30"
    )}>
      <h4 className="flex items-center text-sm font-medium text-slate-800">
        {title}
        {isActive && (
          <span className="ml-2 text-xs font-medium text-blue-600">Active</span>
        )}
      </h4>

      {/* Water Level - shows transition */}
      <div className="flex items-baseline justify-between">
        <span className="text-sm text-slate-600">{t('timeline.water_level')}</span>
        <div className="flex items-center gap-2">
          <WaterLevelChange
            min={stats.startWaterLevel}
            max={stats.endWaterLevel}
            unit="mm"
            decimals={0}
          />
          <span className={cn(
            "text-xs font-medium px-2 py-0.5 rounded-full",
            stats.trendPercentage > 0 
              ? "text-emerald-700 bg-emerald-50/70" 
              : "text-rose-700 bg-rose-50/70"
          )}>
            {stats.trendPercentage > 0 ? "+" : ""}{stats.trendPercentage}%
          </span>
        </div>
      </div>

      {/* Environment Group */}
      <div className="space-y-2 pt-3 border-t border-slate-100">
        <div className="flex items-center justify-between">
          <span className="text-sm text-slate-600">{t('timeline.temperature')}</span>
          <ValueWithRange
            start={stats.startTemperature}
            end={stats.endTemperature}
            average={stats.averageTemperature}
            unit="°C"
          />
        </div>
        <div className="flex items-center justify-between">
          <span className="text-sm text-slate-600">{t('timeline.ec')}</span>
          <ValueWithRange
            start={stats.startEc}
            end={stats.endEc}
            average={stats.averageEc}
            unit="µS/cm"
            decimals={0}
          />
        </div>
        <div className="flex items-center justify-between">
          <span className="text-sm text-slate-600">{t('timeline.moisture')}</span>
          <ValueWithRange
            start={stats.startMoisture}
            end={stats.endMoisture}
            average={stats.averageMoisture}
            unit="%"
          />
        </div>
      </div>

      {/* Operations Group */}
      <div className="space-y-2 pt-3 border-t border-slate-100">
        <div className="flex items-center justify-between">
          <span className="text-sm text-slate-600">{t('timeline.fill_valve')}</span>
          <div className="flex items-center gap-2">
            <span className="text-xs text-slate-500 font-medium">{stats.fillValveOperations}×</span>
            <span className="font-mono text-sm text-slate-700">{stats.totalFillValveTime}</span>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <span className="text-sm text-slate-600">{t('timeline.drain_valve')}</span>
          <div className="flex items-center gap-2">
            <span className="text-xs text-slate-500 font-medium">{stats.drainValveOperations}×</span>
            <span className="font-mono text-sm text-slate-700">{stats.totalDrainValveTime}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

function TimelineActionItem({ action }: TimelineActionItemProps) {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <div className="rounded-md border border-slate-200/60 bg-white/80 hover:bg-slate-50/80 transition-colors">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full px-2.5 py-1.5 flex items-center justify-between group"
      >
        <div className="font-medium text-xs text-slate-600 group-hover:text-slate-800">{action.title}</div>
        <div className="flex items-center gap-1.5">
          <time className="text-xs text-slate-400 tabular-nums">
            {new Date(action.timestamp).toLocaleTimeString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false
            })}
          </time>
          {action.parameters && (
            <ChevronDown className={cn(
              "h-3 w-3 transition-transform text-slate-400",
              isExpanded && "rotate-180"
            )} />
          )}
        </div>
      </button>
      {isExpanded && action.parameters && (
        <div className="px-2.5 py-1 text-xs border-t border-slate-100 bg-slate-50/50">
          {Object.entries(action.parameters).map(([key, value]) => (
            <div key={key} className="flex justify-between">
              <span className="text-slate-500">{key}:</span>
              <span className="font-medium text-slate-600">{value}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  )
} 