import { Trash2 } from 'lucide-react'
import { Control, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/ui/button'
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Textarea } from "@/components/ui/textarea"
import type { CreateUpdateSandStandard } from '@/data/sand-standards'
import type { CalculatedRow } from '@/hooks/useSandCalculations'
import { useState } from 'react'

interface SandBoundaryTableProps {
  control: Control<CreateUpdateSandStandard>
  name: 'minBoundary' | 'maxBoundary'
  title: string
  calculatedRows: CalculatedRow[]
}

export function SandBoundaryTable({
  control,
  name,
  title,
  calculatedRows,
}: SandBoundaryTableProps) {
  const { t } = useTranslation(['admin', 'common'])
  const [jsonInput, setJsonInput] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)

  const {
    fields,
    append,
    remove,
    replace,
  } = useFieldArray({
    control,
    name,
  })

  const handleJsonImport = () => {
    try {
      const data = JSON.parse(jsonInput)
      if (Array.isArray(data)) {
        // Validate each item has the required fields
        const validData = data.every(item => 
          typeof item === 'object' &&
          'meshNumber' in item &&
          'diameterMm' in item &&
          'retainedGrams' in item
        )
        
        if (validData) {
          replace(data)
          setDialogOpen(false)
          setJsonInput('')
        } else {
          throw new Error('Invalid data format')
        }
      } else {
        throw new Error('Data must be an array')
      }
    } catch (error) {
      alert('Invalid JSON format or data structure')
    }
  }

  const addCustomRow = () => {
    append({
      meshNumber: '',
      diameterMm: 0,
      retainedGrams: 0,
    })
  }

  return (
    <div className="space-y-1">
      <div className="flex items-center justify-between">
        <h4 className="text-sm font-medium">{title}</h4>
        <div className="flex gap-1">
          <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
            <DialogTrigger asChild>
              <Button
                variant="outline"
                size="sm"
                className="h-6 text-xs px-2"
              >
                {t('sand_standards.import_json')}
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>{t('sand_standards.import_json_title')}</DialogTitle>
              </DialogHeader>
              <div className="space-y-4">
                <Textarea
                  value={jsonInput}
                  onChange={(e) => setJsonInput(e.target.value)}
                  placeholder='[{"meshNumber": "#5", "diameterMm": 4.0, "retainedGrams": 0.00}, ...]'
                  className="h-[200px] font-mono text-sm"
                />
                <Button onClick={handleJsonImport} className="w-full">
                  {t('sand_standards.import')}
                </Button>
              </div>
            </DialogContent>
          </Dialog>
          <Button
            type="button"
            variant="outline"
            size="sm"
            className="h-6 text-xs px-2"
            onClick={addCustomRow}
          >
            {t('sand_standards.add_row')}
          </Button>
        </div>
      </div>

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow className="h-7">
              <TableHead className="py-0.5 w-[5.5rem] text-[10px]">{t('sand_standards.sieve_number')}</TableHead>
              <TableHead className="py-0.5 w-[80px] text-[10px]">{t('sand_standards.diameter')} (mm)</TableHead>
              <TableHead className="py-0.5 w-[80px] text-[10px]">{t('sand_standards.retained')} (g)</TableHead>
              <TableHead className="py-0.5 w-[80px] text-[10px]">{t('sand_standards.accumulative_retain')} (%)</TableHead>
              <TableHead className="py-0.5 w-[80px] text-[10px]">{t('sand_standards.mass_retained')} (%)</TableHead>
              <TableHead className="py-0.5 w-[80px] text-[10px]">{t('sand_standards.passing')} (%)</TableHead>
              <TableHead className="py-0.5 w-[32px]"></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {fields.map((field, index) => {
              const calculatedRow = calculatedRows[index] || {
                accumulativeRetain: 0,
                massRetained: 0,
                passing: 100,
              }
              
              return (
                <TableRow key={field.id} className="group h-7">
                  <TableCell className="py-0.5">
                    <FormField
                      control={control}
                      name={`${name}.${index}.meshNumber`}
                      render={({ field }) => (
                        <FormItem className="space-y-0">
                          <FormControl>
                            <Input {...field} className="h-5 text-xs w-[5rem]" />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </TableCell>
                  <TableCell className="py-0.5">
                    <FormField
                      control={control}
                      name={`${name}.${index}.diameterMm`}
                      render={({ field }) => (
                        <FormItem className="space-y-0">
                          <FormControl>
                            <Input {...field} type="number" step="0.001" className="h-5 text-xs w-[4.5rem]" />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </TableCell>
                  <TableCell className="py-0.5">
                    <FormField
                      control={control}
                      name={`${name}.${index}.retainedGrams`}
                      render={({ field }) => (
                        <FormItem className="space-y-0">
                          <FormControl>
                            <Input {...field} type="number" step="0.1" className="h-5 text-xs w-[4.5rem]" />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </TableCell>
                  <TableCell className="py-0.5 text-xs text-muted-foreground">
                    {calculatedRow.accumulativeRetain.toFixed(2)}%
                  </TableCell>
                  <TableCell className="py-0.5 text-xs text-muted-foreground">
                    {calculatedRow.massRetained.toFixed(2)}%
                  </TableCell>
                  <TableCell className="py-0.5 text-xs text-muted-foreground">
                    {calculatedRow.passing.toFixed(2)}%
                  </TableCell>
                  <TableCell className="py-0.5">
                    <Button
                      type="button"
                      variant="ghost"
                      size="icon"
                      className="h-5 w-5 opacity-0 group-hover:opacity-100 transition-opacity"
                      onClick={() => remove(index)}
                      tabIndex={-1}
                    >
                      <Trash2 className="h-3 w-3" />
                      <span className="sr-only">{t('common.remove')}</span>
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  )
} 