import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type { 
  SandAnalysisResult, 
  SandAnalysisSession, 
  SandMeasurement 
} from '@/data/sand-analysis'
import { 
  createSandAnalysis, 
  createSession, 
  getSession,
  updateSessionContact
} from '@/data/sand-analysis'

function useSandAnalysis(sessionId?: string) {
  const queryClient = useQueryClient()

  // Query for getting session data
  const {
    data: session,
    isLoading,
    refetch
  } = useQuery({
    queryKey: ['sand-analysis', 'session', sessionId],
    queryFn: () => getSession(sessionId!),
    enabled: Boolean(sessionId),
  })

  // Mutation for creating a new analysis
  const createAnalysisMutation = useMutation({
    mutationFn: async (data: {
      id?: string
      sampleName: string
      measurements: SandMeasurement[]
    }) => createSandAnalysis(sessionId!, data),
    onSuccess: () => {
      // Invalidate and refetch session data to get updated analyses
      queryClient.invalidateQueries({ 
        queryKey: ['sand-analysis', 'session', sessionId] 
      })
    },
  })

  // Mutation for creating a new session
  const createSessionMutation = useMutation({
    mutationFn: async (data?: {
      contactName?: string
      contactEmail?: string
      standardIds?: string[]
    }) => createSession(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ 
        queryKey: ['sand-analysis', 'sessions'] 
      })
    },
  })

  // Mutation for updating session contact information
  const updateContactMutation = useMutation({
    mutationFn: async (data: {
      contactName?: string
      contactEmail?: string
    }) => updateSessionContact(sessionId!, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ 
        queryKey: ['sand-analysis', 'session', sessionId] 
      })
    },
  })

  return {
    session,
    refetchSession: refetch,
    isLoading,
    createAnalysis: createAnalysisMutation.mutateAsync,
    isCreatingAnalysis: createAnalysisMutation.isPending,
    createSession: createSessionMutation.mutateAsync,
    isCreatingSession: createSessionMutation.isPending,
    updateContact: updateContactMutation.mutateAsync,
    isUpdatingContact: updateContactMutation.isPending,
  }
}

export default useSandAnalysis