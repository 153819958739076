import * as Sentry from '@sentry/react'

import { axiosClient } from '@/lib/axios'

const BASE_URI = '/admin/diagnostic'

export interface Diagnostic {
  id: number
  data: string
  deviceId: number
  imei: string
  createdAt: string
}

export interface PaginatedDiagnostics {
  diagnostics: Diagnostic[]
  totalCount: number
}

export const getDiagnostics = async (
  abortSignal: AbortSignal,
  imei?: string | null,
  take = 20,
  page = 0,
  from?: Date,
  to?: Date,
  orgId?: string | null,
) => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      take: take.toString(),
      ...(typeof imei === 'string' && { imei }),
      ...(from && { from: from.toISOString() }),
      ...(to && { to: to.toISOString() }),
      ...(orgId && { orgId }),
    })

    const res = await axiosClient.get<PaginatedDiagnostics>(
      `${BASE_URI}?${params.toString()}`,
      {
        signal: abortSignal,
      },
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export interface DiagnosticSchedule {
  id: number
  intervalMinutes: number
  startDateTime: string
  endDateTime: string
  lastRequestedAt?: string
  deviceId: number
  imei: string
  createdBy: string
  createdAt: string
  deletedAt?: string
}

export interface PaginatedDiagnosticSchedules {
  diagnosticSchedules: DiagnosticSchedule[]
  totalCount: number
}

interface GetDiagnosticSchedulesParams {
  signal: AbortSignal
  imei?: string | null
  take?: number
  page?: number
  orgId?: string | null
  includeDeleted?: boolean | null
}

export const getDiagnosticSchedules = async ({
  signal,
  imei,
  take = 20,
  page = 0,
  orgId,
  includeDeleted,
}: GetDiagnosticSchedulesParams) => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      take: take.toString(),
      ...(typeof imei === 'string' && { imei }),
      ...(orgId && { orgId }),
      ...(includeDeleted && { includeDeleted: includeDeleted.toString() }),
    })

    const res = await axiosClient.get<PaginatedDiagnosticSchedules>(
      `${BASE_URI}/schedule?${params.toString()}`,
      {
        signal,
      },
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export const createDiagnosticSchedule = async (data: {
  imei: string
  intervalMinutes: number
  startDateTime: Date
  endDateTime: Date
}) => {
  try {
    const res = await axiosClient.post(`${BASE_URI}/schedule`, data)
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export const updateDiagnosticSchedule = async (
  id: number,
  data: {
    imei: string
    intervalMinutes: number
    startDateTime: Date
    endDateTime: Date
  },
) => {
  try {
    const res = await axiosClient.put(`${BASE_URI}/schedule/${id}`, data)
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export const deleteDiagnosticSchedule = async (id: number) => {
  try {
    await axiosClient.delete(`${BASE_URI}/schedule/${id}`)
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
} 